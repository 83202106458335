import React, { Component } from "react";
import {
  Alert,
  Badge,
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Button,
  InputGroup,
  Input,
  InputGroupText,
  InputGroupAddon,
} from "reactstrap";
import { FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import { MdModeEdit, MdAdd } from "react-icons/md";
import { AiOutlineStop } from "react-icons/ai";
import ReactTable from "react-table";
import "react-table/react-table.css";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
import * as Constant from "../../constant/general";
import swal from "sweetalert";
import SwalLoading from "../../components/SwalLoading";
import moment from "moment";

const ReactTableFixedCol = withFixedColumns(ReactTable);

class SessionLogs extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.getSessionLogs(this.state.searchNRIC).then(() => {
      const { error } = this.props.sessionLogs;
      if (error) {
        swal({
          title: `${error.title} ${error.code}`,
          text: JSON.stringify(error.message),
          icon: "error",
          // timer: 1000,
        });
        return;
      }
    });
  }

  render() {
    const columns = [
      {
        Header: "Name",
        id: "name",
        accessor: (d) => {
          return d.name;
        },
        filterable: true,
        sortable: false,
        filterMethod: (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null,
        style: {},
      },
      {
        Header: "Username",
        id: "username",
        accessor: (d) => {
          return d.username;
        },
        filterable: true,
        sortable: false,
        filterMethod: (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null,
        style: {},
      },
      {
        Header: "Request IP Address",
        id: "request_ip",
        accessor: (d) => {
          return d.request_ip;
        },
        filterable: true,
        sortable: false,
        filterMethod: (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null,
        style: {},
      },
      {
        Header: "Access IP Address",
        id: "access_ip",
        accessor: (d) => {
          return d.access_ip;
        },
        filterable: true,
        sortable: false,
        filterMethod: (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null,
        style: {},
      },
      {
        Header: "Created",
        id: "createdAt",
        accessor: (d) => {
          return moment(d.createdAt).format("YYYY-MM-DD HH:mm");
        },
        filterable: true,
        sortable: false,
        filterMethod: (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null,
        style: {},
      },
      {
        Header: "Updated",
        id: "updatedAt",
        accessor: (d) => {
          return moment(d.updatedAt).format("YYYY-MM-DD HH:mm");
        },
        filterable: true,
        sortable: false,
        filterMethod: (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null,
        style: {},
      },
    ];
    return (
      <React.Fragment>
        <div className="animated fadeIn">
          <Row>
            <Card style={{ width: "100%" }}>
              <CardHeader></CardHeader>

              <ReactTable
                className="-striped -highlight"
                loading={this.props.sessionLogs.loading}
                columns={columns}
                noDataText="No data found!"
                data={this.props.sessionLogs.result}
                defaultPageSize={10}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                getTdProps={() => ({ style: { textAlign: "center" } })}
              />
            </Card>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default SessionLogs;
