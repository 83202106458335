import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import ReactLoading from "react-loading";
import * as Constant from "../../constant/general";
import Loadable from "react-loading-overlay";
import { FormInputError } from "../../components/FormInputError";
import queryString from "query-string";
import swal from "sweetalert";

class ExternalLogin extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentWillMount() {
    const params = queryString.parse(this.props.location.search);
    this.props.authenticateInternal(params.sessionId).then(() => {
      let { error } = this.props.auth;
      if (error) {
        swal(error.title, error.message, "info");
        return;
      }
      this.props.history.replace("/");
    });
  }

  render() {
    return <div></div>;
  }
}

export default ExternalLogin;
