import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import ReactLoading from "react-loading";
import * as Constant from "../../constant/general";

class ResetPasswordSuccess extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="p-5">
                <center>
                  <h1>Password reset successful</h1>
                </center>
                <br />
                <p>
                  You have successfully reset your password. Use this new
                  password to login.
                </p>
                <br />
                <Button
                  color="info"
                  className="px-4"
                  onClick={() => this.props.history.replace(Constant.Login)}
                >
                  Back To Login
                </Button>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ResetPasswordSuccess;
