function AlertError(code = 0, title = null, message = null) {
  this.code = code;
  this.title = title;
  this.message = message;
}

AlertError.prototype.setResponse = function (response, title = null) {
  this.code = response.status;
  this.title = title;
  try {
    this.message = response.headers.get("Message");
  } catch (ex) {
    this.message = response.headers.Message;
  }

  /*
    var res =response.json().then(function(object) {
      console.log(object)
    })
    */

  if (this.message == null) {
    switch (this.code) {
      case 400:
        this.message = "400 Bad Request";
      case 401:
        this.message = "401 Unauthorized";
      case 403:
        this.message = "403 Forbidden";
      case 404:
        this.message = "404 Not Found";
      case 409:
        this.message = "409 Conflict";
      case 500:
        this.message = "500 Internal Server Error";
      case 503:
        this.message = "503 Service Unavailable";
      default:
        this.message = "Error code " + this.code;
    }
  }
};

export default AlertError;
