import i18n from "../i18n/index";

// localStorage
export const LOCAL_STORAGE_LOGIN_SESSION = "loginSession";
export const STORE_LOCAL_STORAGE_LOGIN_SESSION = "store_loginSession";

// react router path name
export const Home = "/";
export const Search = "/search";
export const SearchAIA = "/searchAIA";
export const SearchAviva = "/searchAviva";
export const SearchLogs = "/searchLogs";
export const AppAccess = "/appAccess";
export const AppAccessLogs = "/appAccessLogs";
export const ExternalLogin = "/eLogin";
export const SessionLogs = "/sessionLogs";

export const Login = "/login";
export const Logout = "/logout";
export const Verification = "/verification";
export const Register = "/register";
export const ForgotPassword = "/forgotPassword";
export const ForgotPasswordSuccess = "/forgotPasswordSuccess";
export const ResetPasswordRequest = "/resetPasswordRequest";
export const ResetPasswordRequestSuccess = "/resetPasswordRequestSuccess";
export const ResetPassword = "/resetPassword";
export const ResetPasswordSuccess = "/resetPasswordSuccess";
export const NUMBER_OF_CHAR_MINIMUM_SEARCH = 5;
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
