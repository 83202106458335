import * as types from "./AddVisitActionTypes";

var initialState = {
  loading: false,
  result: [],
  error: null,
  visitHistory: []
};

function AddVisitReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ADD_VISIT_URL_REQUEST:
    case types.GET_VISIT_DETAIL_URL_REQUEST:
    case types.GET_VISIT_HISTORY_REQUEST:
    case types.GET_ADD_CREDIT_NOTE_BILLING_URL_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
      break;

    case types.GET_ADD_VISIT_URL_SUCCESS:
    case types.GET_VISIT_DETAIL_URL_SUCCESS:
    case types.GET_ADD_CREDIT_NOTE_BILLING_URL_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        status: true,
        result: action.payload,
      });
      break;
    case types.GET_VISIT_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        status: true,
        visitHistory: action.payload,
      });
      break;

    case types.GET_ADD_VISIT_URL_FAILURE:
    case types.GET_VISIT_DETAIL_URL_FAILURE:
    case types.GET_VISIT_HISTORY_FAILURE:
    case types.GET_ADD_CREDIT_NOTE_BILLING_URL_FAILURE:
      return Object.assign({}, state, {
        result: [],
        loading: false,
        error: action.error,
      });
      break;
    default:
      return state;
  }

  return state;
}

export default AddVisitReducer;
