import axios from "axios";
import * as types from "./AppAccessLogsActionTypes";
import AlertError from "../../utility/AlertError";
import * as Constant from "../../constant/general";
import history from "../../utility/History";

export function getAll() {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_LOG_REQUEST });

    var user = getState().Auth.result;

    await axios({
      method: "GET",
      url: "/v1/appAccessLog/all",
      headers: {
        authorization: user.access_token,
      },
    }).then((response) => {
      if (response.data && response.data.result) {
        dispatch({
          type: types.GET_LOG_SUCCESS,
          payload: response.data.result,
        });
      } else {
        var err_msg =
          response.response.data.error || response.response.data.message;
        let alertError = new AlertError(
          response.response.status,
          "Error",
          err_msg
        );
        dispatch({
          type: types.GET_LOG_FAILURE,
          error: alertError,
        });
        if (response.response.status == 401) {
          localStorage.removeItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
        }
      }
    });
  };
}

export function getLogs(page, pageSize, filtered = [], sorted = []) {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_LOG_REQUEST });

    var user = getState().Auth.result;

    await axios({
      method: "POST",
      url: `/v1/appAccessLog?PAGE_NUMBER=${page}&NUMBER_OF_ITEMS=${pageSize}`,
      headers: {
        authorization: user.access_token,
      },
      data: { filtered: filtered, sorted: sorted }

    }).then((response) => {
      if (response.data && response.data.result) {
        dispatch({
          type: types.GET_LOG_SUCCESS,
          payload: response.data.result,
        });
      } else {
        var err_msg =
          response.response.data.error || response.response.data.message;
        let alertError = new AlertError(
          response.response.status,
          "Error",
          err_msg
        );
        dispatch({
          type: types.GET_LOG_FAILURE,
          error: alertError,
        });
        if (response.response.status == 401) {
          localStorage.removeItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
        }
      }
    });
  };
}

export const exportData = (from, to) => {
  return async (dispatch, getState) => {
    var user = getState().Auth.result;
    try {
      const res = await axios({
        method: "POST",
        url: `/v1/appAccessLog/export?from=${from}&to=${to}`,
        headers: {
          authorization: user.access_token,
        },
        responseType: "blob"
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `AppAccessLogs_${from}_${to}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); 

    } catch (err) {
      const alertError = new AlertError(500, "Error", err.message);
      dispatch(alertError); 
    }
  }
}