import React, { Component } from "react";
import {
  Alert,
  Badge,
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Button,
  InputGroup,
  Input,
  InputGroupText,
  InputGroupAddon,
  FormGroup,
  Label,
} from "reactstrap";
import { FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import { MdModeEdit, MdAdd } from "react-icons/md";
import { AiOutlineStop } from "react-icons/ai";
import ReactTable from "react-table";
import "react-table/react-table.css";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
import * as Constant from "../../constant/general";
import swal from "sweetalert";
import swal2 from "sweetalert2";
import SwalLoading from "../../components/SwalLoading";
import moment from "moment";
import DatePicker from "react-datepicker";
import { escape, unescape } from "html-escaper";
import Loadable from "react-loading-overlay";
import { NUMBER_OF_CHAR_MINIMUM_SEARCH } from "../../constant/general";
import AddVisit from "./AddVisit";
import SelectVisit from "./SelectVisit";
import MemberDetailAIA from "./MemberDetailAIA";
import MemberDetailAviva from "./MemberDetailAviva";
import { CompoDropdown } from "../../components/FormComponents";


class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      result: [],
      date: moment().format("YYYY-MM-DD"),
      searchBy: null, // "searchNRIC", "searchPatientName, "searchVisitNumber", "searchCompany"
      searchNRIC: "",
      searchName: "",
      searchVisitNumber: "",
      searchPatientName: "",
      searchClinicCode: "",
      searchCompany: "",
      loading: false,
      searchProgram: "other",
      clinicCode: null,
      programOptions: [
        { value: 'other', label: 'Search under other program', imgSrc: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/9+BogAAAABJRU5ErkJggg==", imgStyle: { height: 25 } },
        { value: 'AIA', label: 'Search under AIA program', imgSrc: "../../../img/AIALogo.png", imgStyle: { height: 25 } },
        { value: 'Aviva', label: 'Search under Singlife program', imgSrc: "../../../img/SinglifeLogo.png", imgStyle: { height: 25 } },
        { value: 'FWD', label: 'Search under FWD TRAVEL Program', imgSrc: "../../../img/FWDLogo.png", imgStyle: { height: 25 } },
      ],
      memberDetail: null,
      visitHistory: null,


    };
    this.onSubmitAddVisit = this.onSubmitAddVisit.bind(this);
    this.getVisitHistory = this.getVisitHistory.bind(this);
    this.onChangeFieldClinicListing = this.onChangeFieldClinicListing.bind(this);

  }
  componentDidMount() {
    const loginData = JSON.parse(
      localStorage.getItem(Constant.LOCAL_STORAGE_LOGIN_SESSION)
    ).data;
    const role =
      loginData && loginData.meta_data && loginData.meta_data.role_types
        ? loginData.meta_data.role_types.length
          ? loginData.meta_data.role_types[0].name
          : loginData.meta_data.role_types.name
        : null;

    if (loginData) {
      this.props.isEligibleForMedicalChit().then(() => {
        const { error } = this.props.search;
        if (error) return swal({
          title: "Error",
          text: error,
          icon: "error",
        });
      });

      if (role && ["admin", "provider network"].indexOf(role.toLowerCase()) != -1) {
        this.props.getClinic().then(() => {
          const { errorClinic } = this.props.search;
          if (errorClinic) return swal({
            title: "Error",
            text: errorClinic,
            icon: "error",
          });

          // console.log(this.props.search.resultClinic, "RESULT CLINIC")
        });

      }
    }
  }

  showLoadingMessage() {
    swal({
      title: 'Please wait',
      text: 'Loading...',
      icon: 'info',
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
    });
  }

  hideLoadingMessage() {
    swal.close();
  }
  onInputChangeHandler(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleChangeProgram(event) {
    this.setState({
      [event.target.name]: event.target.value,
      result: [],
      memberDetail: null,
      searchVisitNumber: "",
      searchPatientName: "",
      searchNRIC: "",
      searchCompany: ""
    });
  }

  onSearchNRICButtonClicked() {
    // console.log(this.state, "STATE")
    if (!this.state.searchNRIC && this.state.date && !this.state.searchVisitNumber && !this.state.searchPatientName && !this.state.searchCompany) {
      swal({
        title: "Search Member",
        text: "Please input one of the search fields.",
        icon: "warning",
      });
      return;
    }

    let temp = [this.state.searchNRIC, this.state.searchPatientName, this.state.searchVisitNumber, this.state.searchCompany];
    temp = temp.filter(item => !!item);
    if (temp && temp.length > 1) {
      swal({
        title: "Search Member",
        text: "You are only allowed to search one category at a time.",
        icon: "warning",
      });
      return;
    }

    const valueSearch = temp[0];
    if (valueSearch && valueSearch.length < NUMBER_OF_CHAR_MINIMUM_SEARCH) {
      swal({
        title: "Search Member",
        text: "Please input at least 5 characters to proceed",
        icon: "warning",
      });
      return;
    }

    let date = moment(this.state.date, "YYYY-MM-DD", true);
    if (!date.isValid()) {
      swal({
        title: "Search Member",
        text: "Invalid visit date, please enter as YYYY-MM-DD",
        icon: "error",
        // timer: 1000,
      });
      return;
    }

    this.setState({
      result: [],
      loading: true,
    });

    if (["AIA", "Aviva", "FWD", "other"].indexOf(this.state.searchProgram) != -1) {
      // console.log("STEP 2")
      this.props.getMemberV2({
        nric: this.state.searchNRIC,
        visitNo: this.state.searchVisitNumber,
        patientName: this.state.searchPatientName,
        company: this.state.searchCompany,
        date: this.state.date,
        type: this.state.searchProgram,
        search: this.state.searchBy
      }).then((res) => {
        // console.log("STEP 3", res)
        const { error, result } = this.props.search;

        if (error) {
          this.setState({ loading: false });
          if (error.code != 404) {
            if (this.state.searchProgram && this.state.searchProgram === "other") {
              // use swal 2 instead for other program
              return swal2.fire({
                type: "error",
                title: `${error.title} ${error.code}`,
                text: error.message,
                html: error.message,
                icon: "error",
                confirmButtonColor: "#136AC3",
                // timer: 1000,
              });
            } else {
              return swal({
                title: `${error.title} ${error.code}`,
                text: error.message,
                icon: "error",
                // timer: 1000,
              });
            }


          }
        }

        let newResult = JSON.parse(JSON.stringify(result.list));
        newResult = newResult.map((el) => {
          return el
        })
        // console.log(newResult, "NEW RESULT")
        if (newResult && newResult.length > 0) {
          const { searchNRIC, searchPatientName, searchVisitNumber, searchCompany } = this.state;
          let searchBy = this.state.searchBy;
          if (searchNRIC && searchNRIC != "") searchBy = "searchNRIC";
          if (searchPatientName && searchPatientName != "") searchBy = "searchPatientName";
          if (searchVisitNumber && searchVisitNumber != "") searchBy = "searchVisitNumber";
          if (searchCompany && searchCompany != "") searchBy = "searchCompany";
          // console.log(searchNRIC, searchPatientName, searchVisitNumber, searchBy)
          this.setState({
            result: newResult,
            searchBy: searchBy,
            loading: false
          });
          const newRes = newResult.filter((el) => el.s === "Terminated");
          if (newRes.length === newResult.length) {
            return swal({
              title: `Error`,
              text: "Member is terminated",
              icon: "error",
              // timer: 1000,
            });
          }
        } else {
          // swal({
          //   title: "Error",
          //   text: "Member not found",
          //   icon: "error",
          //   // timer: 1000,
          // });
          this.setState({
            result: [],
            loading: false,
          });
        }
      })



      // this.props.getMember(this.state.searchNRIC).then(() => {
      //   const { error } = this.props.search;
      //   if (error) {
      //     console.log("error.code", error.code);
      //     if (error.code == 404) {
      //       swal({
      //         title: "Member not found",
      //         text: "",
      //         icon: "error",
      //         // timer: 1000,
      //       });
      //       return;
      //     }

      //     swal({
      //       title: `${error.title} ${error.code}`,
      //       text: JSON.stringify(error.message),
      //       icon: "error",
      //       // timer: 1000,
      //     });
      //     return;
      //   }
      // });
    }
  }

  onsearchNameButtonClicked() {
    // swal("not handle yet");
  }

  handleKeyNricPress(target) {
    if (target.charCode == 13) {
      this.onSearchNRICButtonClicked();
    }
  }

  handleKeyNamePress(target) {
    if (target.charCode == 13) {
      this.onSearchNRICButtonClicked();
    }
  }

  clearAll() {
    this.setState({
      result: [],
      searchNRIC: "",
      searchName: "",
      searchVisitNumber: "",
      searchPatientName: "",
      searchCompany: "",
      searchProgram: "other",
      date: moment().format("YYYY-MM-DD"),
      loading: false,
    });
  }

  onSubmitAddVisit(userId, visitType, patientId, visitDate, portal, isIEC, sd, td, cid, dataCreditNote = null) {
    // console.log(userId, visitType, patientId, visitDate, portal, isIEC,sd, td, cid, dataCreditNote, "==on add visit")
    this.showLoadingMessage();
    if (dataCreditNote) {
      this.props.getAddCreditNoteBillingUrl(dataCreditNote).then(() => {
        this.hideLoadingMessage();
        if (this.props.addVisit.error) {
          swal({
            title: "Error",
            text: this.props.addVisit.error.message,
            icon: "error",
          });
        } else {
          let currentUrl = this.props.addVisit.result.url;
          window.open(
            currentUrl,
            "_blank"
          );
        }
      }).catch(error => {
        // Hide loading message on error
        this.hideLoadingMessage();

        // Handle the error as needed
        console.error('Error:', error);
        swal({
          title: "Error",
          text: error.message,
          icon: "error",
        });
      })
    } else {
      this.props.getAddVisitUrl(userId, visitType, patientId, visitDate, portal, isIEC, sd, td, cid).then(() => {
        this.hideLoadingMessage();
        if (this.props.addVisit.error) {
          swal({
            title: "Error",
            text: this.props.addVisit.error.message,
            icon: "error",
          });
        } else {
          let currentUrl = this.props.addVisit.result.url;
          window.open(
            currentUrl,
            "_blank"
          );
        }
      }).catch(error => {
        // Hide loading message on error
        this.hideLoadingMessage();

        // Handle the error as needed
        console.error('Error:', error);
        swal({
          title: "Error",
          text: error.message,
          icon: "error",
        });
      });

    }
  }

  onSubmitGetVisit(userId, portal) {
    if (userId === null && portal) {
      return window.open(
        portal,
        "_blank"
      );

    }
    this.props.getVisitUrl(`3GSW${userId}`, this.state.searchVisitNumber, portal).then(() => {
      if (this.props.addVisit.error) {
        return swal({
          title: this.props.addVisit.error.title,
          text: this.props.addVisit.error.message,
          icon: "error",
        });
      } else {
        let currentUrl = this.props.addVisit.result.url;
        return window.open(
          currentUrl,
          "_blank"
        );
      }
    })
  }
  getVisitHistory(patientId, sd, portal) {
    this.setState({ visitHistory: null });

    this.props.getVisitHistory(patientId, sd, portal).then(() => {
      // console.log(this.props, "PROPSS")
      if (this.props.addVisit.error) {
        this.setState({ visitHistory: [] })
        // return swal({
        //   title: "Error",
        //   text: this.props.addVisit.error.message,
        //   icon: "error",
        // });
      } else {
        this.setState({ visitHistory: this.props.addVisit.visitHistory })
      }
    })
  }
  handleSearchInput(role) {
    const { searchProgram } = this.state;
    const visitDate = <DatePicker
      selected={
        moment(this.state.date, "YYYY-MM-DD").isValid()
          ? moment(this.state.date, "YYYY-MM-DD").toDate()
          : undefined
      }
      onSelect={(date) => {
        let event = {
          target: {
            name: "date",
            value: date
              ? moment(date).format("YYYY-MM-DD")
              : "",
          },
        };
        this.onInputChangeHandler(event);
      }}
      dropdownMode="select"
      showMonthDropdown
      showYearDropdown
      customInput={
        <FormGroup>
          <Label>Visit Date</Label>
          <InputGroup>
            <Input
              style={
                this.state.dateErrorMessage
                  ? {
                    borderColor: "red",
                  }
                  : null
              }
              autoComplete="off"
              placeholder="YYYY-MM-DD"
              value={this.state.date ? this.state.date : ""}
              name="date"
              onChange={(event) =>
                this.onInputChangeHandler(event)
              }
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i className="fa fa-calendar"></i>
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          {this.state.dateErrorMessage ? (
            <p
              style={{
                color: "red",
                fontSize: "12px",
              }}
            >
              {this.state.dateErrorMessage}
            </p>
          ) : null}
        </FormGroup>
      }
    />

    const visitNumber = <FormGroup>
      <Label>Visit Number (All Instances)</Label>
      <InputGroup>
        <Input
          onKeyPress={this.handleKeyNricPress.bind(this)}
          type="text"
          name="searchVisitNumber"
          onChange={(e) => this.onInputChangeHandler(e)}
          id="searchVisitNumber"
          value={this.state.searchVisitNumber}
          required
          placeholder="EV123456"
        />
      </InputGroup>
    </FormGroup>

    const nricMemberId = <FormGroup>
      <Label>NRIC / Member ID {["Aviva", "AIA", "FWD"].indexOf(this.state.searchProgram) > -1 && '*'}</Label>
      <InputGroup>
        <Input
          onKeyPress={this.handleKeyNricPress.bind(this)}
          type="text"
          name="searchNRIC"
          onChange={(e) => this.onInputChangeHandler(e)}
          id="searchNRIC"
          value={this.state.searchNRIC}
          required
          placeholder="NRIC / Member ID"
        />
      </InputGroup>
    </FormGroup>

    const patientName = <FormGroup>
      <Label>Patient Name</Label>
      <InputGroup>
        <Input
          onKeyPress={this.handleKeyNricPress.bind(this)}
          type="text"
          name="searchPatientName"
          onChange={(e) => this.onInputChangeHandler(e)}
          id="searchPatientName"
          value={this.state.searchPatientName}
          required
          placeholder="Patient Name"
        />
      </InputGroup>
    </FormGroup>
    const company = <FormGroup>
      <Label>Company Name (for checkup with valid medical chit only)</Label>
      <InputGroup>
        <Input
          type="text"
          name="searchCompany"
          onChange={(e) => this.onInputChangeHandler(e)}
          id="searchCompany"
          value={this.state.searchCompany}
          required
          placeholder="Company Name"
        />
      </InputGroup>
    </FormGroup>

    const search = <FormGroup>
      <Label>&nbsp;</Label>
      <InputGroup>
        <Button
          color="primary"
          onClick={() => this.onSearchNRICButtonClicked()}
        >
          Search
        </Button>
      </InputGroup>
    </FormGroup>

    const clearAll = <FormGroup>
      <Label>&nbsp;</Label>
      <InputGroup>
        <Button
          onClick={() => this.clearAll()}
          type="submit"
          color="primary"
        >
          Clear All
        </Button>
      </InputGroup>
    </FormGroup>

    const notes = <Row>
      <Col sm="6">
        <label style={{ fontWeight: "normal" }}>
          {role && ["Provider Network", "Admin", "Support"].indexOf(role) != -1 && searchProgram === "other" && (
            <React.Fragment>
              * Visit number search means finding data from visit database.<br />
            </React.Fragment>
          )}
          * Member search means finding data from member database.
        </label>
      </Col>
    </Row>

    if (searchProgram === "other") {
      return <div>
        <Row>
          <Col sm="4">
            {visitDate}
          </Col>
          {role && role.toLowerCase() !== "clinic" && <Col sm="4">
            {visitNumber}
          </Col>}
        </Row>
        {this.props.search.isEligibleForMedicalChit && this.props.search.isEligibleForMedicalChit === true ? <div>
          <Row>
            <Col sm="4">
              {nricMemberId}
            </Col>
            <Col sm="4">
              {patientName}
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              {company}
            </Col>
            <Col sm="1">
              {search}
            </Col>
            <Col sm="2">
              {clearAll}
            </Col>
          </Row>
        </div> : <Row>
          <Col sm="4">
            {nricMemberId}
          </Col>
          <Col sm="4">
            {patientName}
          </Col>
          <Col sm="1">
            {search}
          </Col>
          <Col sm="2">
            {clearAll}
          </Col>
        </Row>}
        {notes}
      </div>
    } else if (searchProgram === "AIA" || searchProgram === "Aviva" || searchProgram === "FWD") {
      return <div>
        <Row>
          <Col sm="4">
            {visitDate}
          </Col>
          <Col sm="4">
            {nricMemberId}
          </Col>
          <Col sm="1">
            {search}
          </Col>
          <Col sm="2">
            {clearAll}
          </Col>
        </Row>

        {notes}
      </div>
    }

  }
  onChangeFieldClinicListing(fieldKey, event, item) {
    this.setState(prevState => ({
      clinicCode: event
    }));
  }
  render() {
    // console.info(this.state, "STATE")
    const loginData = JSON.parse(
      localStorage.getItem(Constant.LOCAL_STORAGE_LOGIN_SESSION)
    ).data;
    const role =
      loginData && loginData.meta_data && loginData.meta_data.role_types
        ? loginData.meta_data.role_types.length
          ? loginData.meta_data.role_types[0].name
          : loginData.meta_data.role_types.name
        : null;

    const columns = [
      {
        Header: "Name",
        id: "n",
        accessor: (d) => {
          return d.n || "-";
        },
        filterable: true,
        sortable: false,
        filterMethod: (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null,
        style: {},
        width: 200
      },
      {
        Header: "NRIC / Member ID",
        id: "nric",
        accessor: (d) => {
          return d.nric ? d.nric2 ? `${d.nric} / ${d.nric2}` : d.nric : "-";
        },
        filterable: true,
        sortable: false,
        filterMethod: (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null,
        style: {},
        width: 150
      },
      {
        Header: "Status",
        id: "status",
        accessor: (d) => {
          return d.s || "-";
        },
        filterable: true,
        sortable: false,
        filterMethod: (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null,
        style: {},
      },
      {
        Header: "Company",
        id: "c",
        accessor: (d) => {
          return d.c || "-";
        },
        filterable: true,
        sortable: false,
        filterMethod: (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null,
        style: {},
      },
      {
        Header: "Program",
        id: "p",
        accessor: (d) => {
          return d.p || "-";
        },
        filterable: true,
        sortable: false,
        filterMethod: (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null,
        style: {},
      },
      {
        Header: "Group Code",
        id: "gc",
        accessor: (d) => {
          return d.gc || "-";
        },
        filterable: true,
        sortable: false,
        filterMethod: (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null,
        style: {},
        width: 100,
        show: role && role.toLowerCase() !== "clinic" ? true : false,
      },
      {
        Header: "Member Type",
        id: "mt",
        accessor: (d) => {
          return d.mt || "-"
        },
        filterable: true,
        sortable: false,
        filterMethod: (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null,
        style: {},
        // width: 150,
      },
      {
        Header: "Start Date",
        id: "sd",
        accessor: (d) => {
          return d.sd ? moment(d.sd, "DD/MM/YYYY").format("DD MMM YYYY").toString() : "-";
        },
        filterable: true,
        sortable: false,
        filterMethod: (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null,
        style: {},
      },
      {
        Header: "End Date",
        id: "td",
        accessor: (d) => {
          return d.td ? moment(d.td, "DD/MM/YYYY").format("DD MMM YYYY").toString() : "-";;
        },
        filterable: true,
        sortable: false,
        filterMethod: (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null,
        style: {},
      },
      {
        Header: "Portal",
        id: "ws",
        accessor: (d) => {
          return d.cc && role && role.toLowerCase() !== "support" ? <Button color="link" onClick={() => { this.onSubmitGetVisit(d.cc, d.ws) }}>{d.ws}</Button> : <Button color="link" onClick={() => { this.onSubmitGetVisit(null, d.ws) }}>{d.ws}</Button>;
        },
        show: role && ["admin", "provider network"].indexOf(role.toLowerCase()) != -1 ? true : false,
        filterable: false,
        sortable: true,
        filterMethod: (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null,
        style: {},
      },

      {
        Header: "Action",
        id: "action",
        accessor: (d) => {
          // check if member is terminated
          const statusMember = d.s;

          //  console.log(statusMember, "==status member")
          return (
            <React.Fragment>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {this.state.searchBy && ["searchNRIC", "searchPatientName"].includes(this.state.searchBy) && statusMember === "Active" ? <AddVisit
                  buttonLabel="Add Normal Visit"
                  buttonColor='primary'
                  buttonClass="mb-2 mr-2"
                  loading={false}
                  data={d}
                  type="normal"
                  visitDate={this.state.date ? this.state.date : ""}
                  isClinic={role && role.toLowerCase() == "clinic" ? true : false}
                  dropdownOptions={this.props.search && this.props.search.resultClinic && this.props.search.resultClinic.data ? this.props.search.resultClinic.data : []}
                  onSubmitAddVisit={this.onSubmitAddVisit}

                /> : ["Admin", "Provider Network"].indexOf(role) != -1 || this.state.searchBy == "searchCompany" ? "" : "-"}

                {this.state.searchBy && ["searchNRIC", "searchPatientName"].includes(this.state.searchBy) && ["other"].indexOf(this.state.searchProgram) != -1 && statusMember === "Active" &&
                  <AddVisit
                    buttonLabel="Add QR Visit"
                    buttonColor='primary'
                    buttonClass="mb-2 mr-2"
                    loading={false}
                    data={d}
                    type="qr"
                    visitDate={this.state.date ? this.state.date : ""}
                    isClinic={role && role.toLowerCase() == "clinic" ? true : false}
                    dropdownOptions={this.props.search && this.props.search.resultClinic && this.props.search.resultClinic.data ? this.props.search.resultClinic.data : []}
                    onSubmitAddVisit={this.onSubmitAddVisit}

                  />
                }
                {this.state.searchBy && this.state.searchBy === "searchCompany" && ["other"].indexOf(this.state.searchProgram) != -1 && statusMember === "Active" &&
                  <AddVisit
                    buttonLabel="Add Normal Visit"
                    buttonColor='primary'
                    buttonClass="mb-2 mr-2"
                    loading={false}
                    data={d}
                    type="checkup"
                    visitDate={this.state.date ? this.state.date : ""}
                    isClinic={role && role.toLowerCase() == "clinic" ? true : false}
                    dropdownOptions={this.props.search && this.props.search.resultClinic && this.props.search.resultClinic.data ? this.props.search.resultClinic.data : []}
                    onSubmitAddVisit={this.onSubmitAddVisit}

                  />
                }

                {
                  //search by visit number
                  d.cc && d.vd && d.coc && this.state.searchBy === "searchVisitNumber" && ["other"].indexOf(this.state.searchProgram) != -1 && ["Admin", "Provider Network"].indexOf(role) != -1 &&
                  <AddVisit
                    buttonLabel="Add Panel Admin Claim"
                    buttonColor='warning'
                    buttonClass="mb-2 mr-2"
                    loading={false}
                    data={d}
                    visitNumber={this.state.searchVisitNumber}
                    type="creditNote"
                    visitDate={this.state.date ? this.state.date : ""}
                    isClinic={role && role.toLowerCase() == "clinic" ? true : false}
                    onSubmitAddVisit={this.onSubmitAddVisit}

                  />}

                {
                  // search by nric/member ID or patientName
                  d.sd && role && this.state.searchBy != "searchCompany"&& ["other"].indexOf(this.state.searchProgram) != -1 && ["Admin", "Provider Network"].indexOf(role) != -1 &&
                  <SelectVisit
                    buttonLabel="Add Panel Admin Claim"
                    buttonColor='warning'
                    buttonClass="mb-2 mr-2"
                    loading={false}
                    data={d}
                    visitNumber={this.state.searchVisitNumber}
                    type="addPanelAdminClaim"
                    visitDate={this.state.date ? this.state.date : ""}
                    isClinic={role && role.toLowerCase() == "clinic" ? true : false}
                    visitHistory={this.state.visitHistory || []}
                    isLoading={this.state.visitHistory === null ? true : false}
                    getVisitHistory={this.getVisitHistory}
                    onSubmitAddVisit={this.onSubmitAddVisit}
                  />
                }
              </div>

              {/* <Button
              color="primary"
              className="mb-2"
              onClick={() => console.log('Add Normal visit')}
              block
            >
              Add Normal visit
            </Button> */}

            </React.Fragment>
          )
        },
        filterable: false,
        sortable: false,
        filterMethod: (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null,
        style: {},
        width: 200,
        show: role && role.toLowerCase() === "support" ? false : true
      }
    ];

    return (
      <React.Fragment>
        <div className="animated fadeIn">
          <Loadable active={this.state.loading} spinner text="Loading...">
            <Row>
              <Card style={{ width: "100%" }}>
                <CardHeader>
                  <Row>
                    {this.state.programOptions.map((program) => (
                      <FormGroup key={program.value} check style={{ paddingLeft: "1rem", width: "200px" }}>
                        <Input
                          type="radio"
                          name="searchProgram"
                          checked={this.state.searchProgram === program.value}
                          onChange={(event) => this.handleChangeProgram(event)}
                          value={program.value}
                          id={`searchProgram${program.value}`}
                          className="custom-radio visually-hidden"
                        />
                        <Label
                          check
                          for={`searchProgram${program.value}`}
                          className={`custom-radio-label ${this.state.searchProgram === program.value ? 'selected' : ''
                            }`}
                        >
                          <Row>
                            {program.label}

                          </Row>
                          <Row className="justify-content-end">
                            {program.imgSrc ? <img className="mr-3" src={program.imgSrc} alt={program.value} style={program.imgStyle} />
                              : <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/9+BogAAAABJRU5ErkJggg==" alt="Empty Image" />}
                          </Row>

                        </Label>
                      </FormGroup>
                    ))}
                  </Row>

                  {this.handleSearchInput(role)}


                </CardHeader>

                {(role && role.toLowerCase() != "support") && <h5 className="text-right mr-3 mb-1"><span style={{ color: "red" }}>*Please click add visit button to confirm patient's eligiblity.</span></h5>}

                {(role && role.toLowerCase() != "support") || (role && role.toLowerCase() === "support" && ["AIA", "Aviva"].indexOf(this.state.searchProgram) == -1) ? <ReactTable
                  className="-striped -highlight"
                  columns={columns}
                  noDataText="No data found!"
                  data={this.state.result}
                  defaultPageSize={10}
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  getTdProps={() => ({ style: { textAlign: "center" } })}
                /> : ""}
              </Card>
            </Row>
            <Row>
              <Col>
                {role && role.toLowerCase() !== "clinic" && this.state.searchProgram === "AIA" && this.state.result.length > 0 ?
                  <MemberDetailAIA
                    search={this.props.search || []}
                    hideSearch={true}
                    searchProgram={this.state.searchProgram}
                  // getMemberAIA={this.handleMemberAIA}
                  /> : ""

                }
                {role && role.toLowerCase() !== "clinic" && this.state.searchProgram === "Aviva" && this.state.result.length > 0 ?
                  <MemberDetailAviva
                    search={this.props.search}
                    hideSearch={true}
                  // getMemberAIA={this.handleMemberAIA}
                  /> : ""

                }
              </Col>
            </Row>
          </Loadable>
        </div>
      </React.Fragment>
    );
  }
}

export default Search;
