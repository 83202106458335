const initState = {
  showAlert: false,
  message: null,
  error: null,
};

function AlertModalReducer(state = initState, action) {
  switch (action.type) {
    case "SHOW_ALERT_MODAL":
      return {
        ...state,
        showAlert: true,
        message: action.message,
        error: action.error,
      };
    case "CLOSE_ALERT_MODAL":
      return {
        ...state,
        showAlert: false,
        message: null,
      };
    default:
      return state;
  }
}

export default AlertModalReducer;
