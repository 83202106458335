import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner
} from "reactstrap";

import ReactTable from "react-table";
import "react-table/react-table.css";

import moment from "moment";
import DatePicker from "react-datepicker";
import swal2 from "sweetalert2";
import debounce from 'lodash/debounce';



import * as Constant from "./../constant/general";

export default class LogHistoryComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateRange: [
        moment().add(-7, "days").toDate(),
        moment().toDate()
      ],
      pages: 1,
      page: 0,
      pageSize: 10,
      filtered: [
        { id: "createdAt", value: [moment().add(-7, "days").toDate(), moment().toDate()] }
      ],

      modal: false,
      startDate: new Date(),
      endDate: new Date()
    };

    this.dateFilter = this.dateFilter.bind(this);
    this.toggle = this.toggle.bind(this);
    this.debouncedFetchData = debounce((state, instance) => {
      this.props.getLogs(state.page + 1, state.pageSize, state.filtered, state.sorted)
        .then(() => {
          this.setState({
            pages: this.props.payload ? Math.ceil(Number(this.props.payload.TOTAL_NUMBER_OF_ITEMS) / this.state.pageSize) : 1,
          })
        });
    }, 500);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error != this.props.error && this.props.error) {
      swal2.fire({
        type: "error",
        title: `${this.props.error.title} ${this.props.error.code}`,
        text: this.props.error.message,
        html: this.props.error.message,
        icon: "error",
        confirmButtonColor: "#136AC3",
      })
    }

  }

  dateFilter({ column, onChange }) {
    let element = column.id;

    if (element == "createdAt") {
      const [startDate, endDate] = this.state.dateRange
      return (<div style={{ position: "realtive", zIndex: 9999 }}
      >
        <DatePicker
          selectsRange={true}
          startDate={startDate || null}
          endDate={endDate || null}
          onChange={(val) => {
            if (val.every(dt => dt === null)) {
              return swal2.fire({
                title: ``,
                text: "The date range cannot be empty. Please select both start and end dates.",
                icon: "warning",
                confirmButtonColor: "#136AC3",
              })
            } else {
              const newFiltered = this.state.filtered.map((el) => {
                if (el.id === "createdAt") {
                  return {
                    id: el.id,
                    value: val
                  }
                } else return el
              })
              this.setState({
                dateRange: val,
                filtered: newFiltered
              });

              if (val.every(element => element !== null)) onChange(val);

            }
          }}
          dropdownMode="select"
          showMonthDropdown
          showYearDropdown
          required={true}
          dateFormat={"dd/MM/yyyy"}
          isClearable={false}
          placeholderText="select date range"
          portal={true}
          portalId="date-picker-portal"
        />
      </div>)
    }
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  render() {
    console.log("THIS PROPS", this.props);
    console.log("THIS STATE", this.state);
    let data = this.props.payload
    let NUMBER_OF_ITEMS = data && data.NUMBER_OF_ITEMS != undefined ? data.NUMBER_OF_ITEMS : 0;
    let PAGE_NUMBER = data && data.PAGE_NUMBER != undefined ? data.PAGE_NUMBER : 0;
    let TOTAL_NUMBER_OF_ITEMS = data && data.TOTAL_NUMBER_OF_ITEMS != undefined ? data.TOTAL_NUMBER_OF_ITEMS : 0;
    let START_OF_ITEM = (PAGE_NUMBER * NUMBER_OF_ITEMS) - NUMBER_OF_ITEMS + 1;
    let END_OF_ITEM = PAGE_NUMBER * NUMBER_OF_ITEMS;
    const columns = this.props.columns.map((col) => {
      let elem = {
        Header: col.header,
        id: col.id,
        accessor: (d) => {
          if (col.id === "createdAt" && moment(d[`${col.id}`]).isValid()) {
            return moment(d[`${col.id}`]).format(Constant.DATETIME_FORMAT);
          }
          return d[`${col.id}`];
        },
        filterable: col.filterable,
        sortable: col.sortable
      }
      if (col.filterable === true) {
        elem.filterMethod = (filter, row) =>
          row[filter.id]
            ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
            : null
      }
      if (col.filterCustom === "dateRange") {
        elem["Filter"] = this.dateFilter

      }
      return elem;
    })

    return (<div className="animated fadeIn">
      <Row>
        <Card style={{ width: "100%" }}>
          <CardHeader>
            <Row>
              <Col><h5>{this.props.headerText}</h5></Col>
              <Col className='d-flex align-items-center justify-content-end'>
                <Button
                  color={"primary"}
                  onClick={this.toggle}
                >
                  {/* <FaFileExport />  */}
                  Export
                </Button>
              </Col>

            </Row>


          </CardHeader>
          <CardBody>
            <span style={{ fontWeight: "400", fontSize: "small", marginTop: "3px", marginBottom: "-5px" }}>
              Showing <span style={{ fontWeight: 600 }}>{START_OF_ITEM}</span>-<span style={{ fontWeight: 600 }}>{END_OF_ITEM}</span> out of <span style={{ fontWeight: 600 }}>{TOTAL_NUMBER_OF_ITEMS}</span> records
            </span>
            <ReactTable
              className="-striped -highlight"
              loading={this.props.loading}
              columns={columns}
              noDataText="No data found!"
              data={this.props.payload ? this.props.payload.data : []}
              defaultPageSize={10}
              pageSizeOptions={[10, 20, 25, 50, 100]}
              getTdProps={() => ({ style: { textAlign: "center" } })}
              showPagination={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pages={this.state.pages}
              pageSize={this.state.pageSize}
              onPageSizeChange={(pageSize, pageIndex) => {
                this.setState({
                  pageSize: pageSize
                })
              }}
              manual
              filtered={this.state.filtered}
              onFilteredChange={filtered => {
                if (this.state.dateRange.every((el) => el === null)) {
                  return swal2.fire({
                    icon: "warning",
                    title: ``,
                    html: "This filter requires a date range selection in the 'Timestamp' column.<br>Please select a date range to proceed.",
                    confirmButtonColor: "#136AC3",

                  })
                } else this.setState({ filtered, page: 0 })
              }}
              page={this.state.page}
              onPageChange={page => { this.setState({ page }) }}
              onFetchData={(state, instance) => {
                this.debouncedFetchData(state, instance);
              }}


            />

          </CardBody>

        </Card>
      </Row>
      <Modal
        isOpen={this.state.modal}
        toggle={this.toggle}
        size='md'
      // centered
      >
        <ModalHeader>Export Data</ModalHeader>
        <ModalBody>
          {/* <Row className="justify-content-center"> */}
          <FormGroup>
            <Label for="startDate">From</Label>
            <DatePicker
              name="startDate"
              id="startDate"
              dateFormat="dd/MM/yyy"
              className="form-control w-100"
              placeholderText="please select date"
              selected={this.state.startDate}
              maxDate={this.state.endDate}
              onChange={(value) => this.setState({ startDate: value })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="endDate">To</Label>
            <DatePicker
              name="endDate"
              id="endDate"
              dateFormat="dd/MM/yyy"
              className="form-control w-100"
              placeholderText="please select date"
              selected={this.state.endDate}
              minDate={this.state.startDate}
              maxDate={new Date()}
              onChange={(value) => this.setState({ endDate: value })}
            />
          </FormGroup>
          <FormGroup>
            <Button
              color="primary"
              block
              // disabled={this.props.payload.loading}
              onClick={() => {
                this.props.exportData(moment(this.state.startDate).format(Constant.DATE_FORMAT), moment(this.state.endDate).format(Constant.DATE_FORMAT))
              }}
            >
              Download
            </Button>
          </FormGroup>

        </ModalBody>
      </Modal>
    </div>);

  }
}
