import * as types from "./AppAccessLogsActionTypes";

var initialState = {
  loading: false,
  result: null,
  error: null,
};

function AppAccessLogsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_LOG_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
      break;
    case types.GET_LOG_SUCCESS:
      return Object.assign({}, state, {
        result: action.payload,
        loading: false,
        error: null,
      });
      break;
    case types.GET_LOG_FAILURE:
      return Object.assign({}, state, {
        result: null,
        loading: false,
        error: action.error,
      });
      break;
    default:
      return state;
  }

}

export default AppAccessLogsReducer;
