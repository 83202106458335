import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createHashHistory } from "history";
import store from "./store/store";
import axios from "axios";
import * as Constant from "./constant/general";
import ScrollToTop from "./utility/ScrollToTop";

// Styles
import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
// Import Font Awesome Icons Set
import "@fortawesome/fontawesome-free/css/all.css";
// Import Main styles for this application
import "../scss/style.scss";
// Temp fix for reactstrap
import "../scss/core/_dropdown-menu-right.scss";
//custom style override
import "../static/css/custom.css";
// Import for Internationalization
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/index";
// Import config js
import "../config";
import swal from "sweetalert";
axios.defaults.baseURL = process.env.BASE_URL;
axios.defaults.timeout = 0;
axios.defaults.headers.post["Content-Type"] = "application/json";
// axios.interceptors.request.use(
//   async (config) => {
//     // console.log("axios config: ", config)
//     if (config.url.includes("/v1/store")) {
//       // console.log("Header Changed")
//     } else {
//       var loginSession = localStorage.getItem(
//         Constant.LOCAL_STORAGE_LOGIN_SESSION
//       );
//       if (loginSession) {
//         loginSession = JSON.parse(loginSession);
//         config.headers.Authorization = loginSession.token;
//       }
//     }

//     return config;
//   },
//   (error) => Promise.reject(error)
// );

axios.interceptors.response.use(
  (response) => {
    //console.log(response)
    return response;
  },
  (error) => {
    //console.log(error.response.request._response)
    console.log("Network Request Failed!");
    console.log(error.response, "ERROR IN REQUEST");
    if (error.response && error.response.status === 401) {
      localStorage.removeItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
      localStorage.removeItem("sid");
      localStorage.removeItem("salt");
      return swal("Token Expired", "Please log in again", "info").then(() => {
        window.location.reload();
      });
    }
    // swal({
    //   icon: "error",
    //   title: "Error in Request",
    //   text: error.response.data.error,
    // });
    return error;
  }
);

import { accountLoggedIn } from "./modules/Auth/AuthAction";

// Containers
import MainContainer from "./modules/Main/MainContainer";
import LoginContainer from "./modules/Auth/LoginContainer";
import Logout from "./modules/Auth/Logout";
import Verification from "./modules/Auth/VerifyContainer";
import ForgotPasswordContainer from "./modules/Auth/ForgotPasswordContainer";
import ForgotPasswordSuccessContainer from "./modules/Auth/ForgotPasswordSuccessContainer";
import ResetPasswordContainer from "./modules/Auth/ResetPasswordContainer";
import ResetPasswordSuccess from "./modules/Auth/ResetPasswordSuccess";
import ExternalLogin from "./modules/Auth/ExternalLoginContainer";

const history = createHashHistory();

import PrivateRoute from "./PrivateRoute";

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Router history={history}>
        <ScrollToTop>
          <Switch>
            <Route path={Constant.ExternalLogin} component={ExternalLogin} />
            <Route path={Constant.Verification} component={Verification} />
            <Route path={Constant.Login} component={LoginContainer} />
            <Route path={Constant.Logout} component={Logout} />
            <Route
              path={Constant.ForgotPassword}
              component={ForgotPasswordContainer}
            />
            <Route
              path={Constant.ForgotPasswordSuccess}
              component={ForgotPasswordSuccessContainer}
            />
            <Route
              path={Constant.ResetPassword}
              component={ResetPasswordContainer}
            />
            <Route
              path={Constant.ResetPasswordSuccess}
              component={ResetPasswordSuccess}
            />
            <PrivateRoute path="/" component={MainContainer}></PrivateRoute>
          </Switch>
        </ScrollToTop>
      </Router>
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);
