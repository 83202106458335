var config = {
  local: {
    //ENVIRONMENT: "local",
    COMPANY: "Aviva",
    COMPANY_NAME: "Aviva Ltd.",
    COMPANY_FULLNAME: "Aviva Singapore",
    COMPANY_REG_NUM: "196900499K",
    GST_REG_NUM: "MR-8500166-8",
    GST_PERCENTAGE: 0.07,

    VENDOR: "MHC Asia Group",
    CALL_CENTER: "6305 4578",
    PHONE_COUNTRY_CODE: "+65",
    DATE_FORMAT: "DD/MM/YYYY",
    TIME_FORMAT: "hh:mm A",

    SSO_APP_SALT: "pXFFhCQ.KFG3L35.q5Pp6NBlfXVv.1",
    SSO_URL: "https://uat-sso.mhcasia.net",
    SSO_API_URL: "https://uat-sso-api.mhcasia.net",
    USE_SSO: true,

    BASE_URL: "http://localhost:3003/api",
    REACT_APP_MAP_KEY: "AIzaSyDS4mQivoiECCvWMQp9I-PtjTw5sA-T5cA",
    REACT_APP_RECAPTCHA_KEY: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",

    STORE_API_STATIC_TOKEN: "Basic bN9aOiNsYa3rVgyZ",

    STRIPE_PK_KEY:
      "pk_test_51IduDaI5zWC6kUx5MXvZTaK9jVSEnKZKK87v5uv1b4RCkMFXypyehwT6Ll7Y78u7nLJCPG5kfmokG2MTE93h6DSY00SOyahcUf",
    EXTERNAL_API_KEY: "avM75jdwWHe3NxxxLhgMYba5RRhBfg",

    WILLIAM_SYSTEM_MHC: "https://www-uat.mhcasia.net",
    WILLIAM_SYSTEM_AIA: "https://www-uat.aiaclinic.com",
    WILLIAM_SYSTEM_AVIVA: "https://www-uat.pcpcare.com",
  },
  development: {
    //ENVIRONMENT: "development",
    COMPANY: "Aviva",
    COMPANY_NAME: "Aviva Ltd.",
    COMPANY_FULLNAME: "Aviva Singapore",
    COMPANY_REG_NUM: "196900499K",
    GST_REG_NUM: "MR-8500166-8",
    GST_PERCENTAGE: 0.07,

    VENDOR: "MHC Asia Group",
    CALL_CENTER: "6305 4578",
    PHONE_COUNTRY_CODE: "+65",
    DATE_FORMAT: "DD/MM/YYYY",
    TIME_FORMAT: "hh:mm A",

    SSO_APP_SALT: "pXFFhCQ.KFG3L35.q5Pp6NBlfXVv.1",
    SSO_URL: "https://uat-sso.mhcasia.net",
    SSO_API_URL: "https://uat-sso-api.mhcasia.net",
    USE_SSO: true,

    BASE_URL: "http://hub-api-dev.mhcasia.net/api",
    REACT_APP_MAP_KEY: "AIzaSyDS4mQivoiECCvWMQp9I-PtjTw5sA-T5cA",
    REACT_APP_RECAPTCHA_KEY: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",

    STORE_API_STATIC_TOKEN: "Basic bN9aOiNsYa3rVgyZ",

    STRIPE_PK_KEY:
      "pk_test_51IduDaI5zWC6kUx5MXvZTaK9jVSEnKZKK87v5uv1b4RCkMFXypyehwT6Ll7Y78u7nLJCPG5kfmokG2MTE93h6DSY00SOyahcUf",
    EXTERNAL_API_KEY: "avM75jdwWHe3NxxxLhgMYba5RRhBfg",

    WILLIAM_SYSTEM_MHC: "https://www-uat.mhcasia.net",
    WILLIAM_SYSTEM_AIA: "https://www-uat.aiaclinic.com",
    WILLIAM_SYSTEM_AVIVA: "https://www-uat.pcpcare.com",
  },
  uat: {
    //ENVIRONMENT: "uat",
    COMPANY: "Aviva",
    COMPANY_NAME: "Aviva Ltd.",
    COMPANY_FULLNAME: "Aviva Singapore",
    COMPANY_REG_NUM: "196900499K",
    GST_REG_NUM: "MR-8500166-8",
    GST_PERCENTAGE: 0.07,

    VENDOR: "MHC Asia Group",
    CALL_CENTER: "6305 4578",
    PHONE_COUNTRY_CODE: "+65",
    DATE_FORMAT: "DD/MM/YYYY",
    TIME_FORMAT: "hh:mm A",

    SSO_APP_SALT: "pXFFhCQ.KFG3L35.q5Pp6NBlfXVv.1",
    SSO_URL: "https://uat-sso.mhcasia.net",
    SSO_API_URL: "https://uat-sso-api.mhcasia.net",
    USE_SSO: true,

    BASE_URL: "https://hub-api-uat.mhcasia.net/api",
    REACT_APP_MAP_KEY: "AIzaSyDS4mQivoiECCvWMQp9I-PtjTw5sA-T5cA",
    REACT_APP_RECAPTCHA_KEY: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",

    STORE_API_STATIC_TOKEN: "Basic bN9aOiNsYa3rVgyZ",

    STRIPE_PK_KEY:
      "pk_test_51IduDaI5zWC6kUx5MXvZTaK9jVSEnKZKK87v5uv1b4RCkMFXypyehwT6Ll7Y78u7nLJCPG5kfmokG2MTE93h6DSY00SOyahcUf",
    EXTERNAL_API_KEY: "avM75jdwWHe3NxxxLhgMYba5RRhBfg",

    WILLIAM_SYSTEM_MHC: "https://www-uat.mhcasia.net",
    WILLIAM_SYSTEM_AIA: "https://www-uat.aiaclinic.com",
    WILLIAM_SYSTEM_AVIVA: "https://www-uat.pcpcare.com",
  },
  production: {
    //ENVIRONMENT: "production",
    COMPANY: "Aviva",
    COMPANY_NAME: "Aviva Ltd.",
    COMPANY_FULLNAME: "Aviva Singapore",
    COMPANY_REG_NUM: "196900499K",
    GST_REG_NUM: "MR-8500166-8",
    GST_PERCENTAGE: 0.07,

    VENDOR: "MHC Asia Group",
    CALL_CENTER: "6305 4578",
    PHONE_COUNTRY_CODE: "+65",
    DATE_FORMAT: "DD/MM/YYYY",
    TIME_FORMAT: "hh:mm A",

    SSO_APP_SALT: "BYBfnZuIPfJVxNUkCCcTk8kQjnelpx",
    SSO_URL: "https://identity.mhcasia.net",
    SSO_API_URL: "https://identity-api.mhcasia.net",
    USE_SSO: true,

    BASE_URL: "https://hub-api.mhcasia.net/api",
    REACT_APP_MAP_KEY: "AIzaSyDS4mQivoiECCvWMQp9I-PtjTw5sA-T5cA",
    REACT_APP_RECAPTCHA_KEY: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",

    STORE_API_STATIC_TOKEN: "Basic bN9aOiNsYa3rVgyZ",

    STRIPE_PK_KEY:
      "pk_test_51IduDaI5zWC6kUx5MXvZTaK9jVSEnKZKK87v5uv1b4RCkMFXypyehwT6Ll7Y78u7nLJCPG5kfmokG2MTE93h6DSY00SOyahcUf",
    EXTERNAL_API_KEY: "L5VrDhBjKu5Y3BSZ97hMYtDRYQR64A",

    WILLIAM_SYSTEM_MHC: "https://www.mhcasia.net",
    WILLIAM_SYSTEM_AIA: "https://www.aiaclinic.com",
    WILLIAM_SYSTEM_AVIVA: "https://www.pcpcare.com",
    WILLIAM_SYSTEM_GLOBAL8: "https://www.myglobalbenefit.com",
  },
};

const env = process.env.APP_ENV || "local";
console.log("Environment = ", env);

// Overwrite process.env
const envConfig = config[env];
Object.keys(envConfig).forEach((key) => {
  if (!process.env[key]) {
    process.env[key] = envConfig[key];
  }
});
