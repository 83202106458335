import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SessionLogs from "./SessionLogs";
import { getSessionLogs } from "./SessionLogsAction";

const mapStateToProps = (state, ownProps) => {
  return {
    sessionLogs: state.SessionLogs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getSessionLogs }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionLogs);
