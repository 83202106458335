import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Main from "./Main";
import { authenticateAction } from "../Auth/AuthAction";

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ authenticateAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
