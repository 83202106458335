import React from "react";
import * as Constant from "./constant/general";

import Search from "./modules/Search/SearchContainer";
import SearchLogs from "./modules/SearchLogs/SearchLogsContainer";
import AppAccess from "./modules/AppAccess/AppAccessContainer";
import AppAccessLogs from "./modules/AppAccessLogs/AppAccessLogsContainer";
import SessionLogs from "./modules/SessionLogs/SessionLogsContainer";

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: Constant.Search,
    exact: true,
    name: "Search Member / Visits",
    component: Search,
  },
  {
    path: Constant.SearchLogs,
    exact: true,
    name: "Search Logs",
    component: SearchLogs,
  },
  {
    path: Constant.SessionLogs,
    exact: true,
    name: "Session Logs",
    component: SessionLogs,
  },
  {
    path: Constant.AppAccessLogs,
    exact: true,
    name: "App Access Logs",
    component: AppAccessLogs,
  },
];

export default routes;
