import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as router from "react-router-dom";
import { Container } from "reactstrap";
import * as Constant from "../../constant/general";

import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppBreadcrumb2 as AppBreadcrumb,
  AppSidebarNav2 as AppSidebarNav,
} from "@coreui/react";
// sidebar nav config
import generateNav from "../../_nav";
// routes config
import routes from "../../routes";
import { checkToken } from "../Auth/AuthAction";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class Main extends Component {
  constructor(props, context) {
    super(props, context);
    this.props.authenticateAction();
  }

  loading() {
    <div className="animated fadeIn pt-1 text-center">Loading...</div>;
  }

  signOut(e) {
    e.preventDefault();
    this.props.history.push("/login");
  }

  componentDidMount() {
    // this.props.checkToken()
  }

  render() {
    // sidebar menu
    const loginData = JSON.parse(
      localStorage.getItem(Constant.LOCAL_STORAGE_LOGIN_SESSION)
    ).data;
    const role =
      loginData && loginData.meta_data && loginData.meta_data.role_types
        ? loginData.meta_data.role_types.length
          ? loginData.meta_data.role_types[0].name
          : loginData.meta_data.role_types.name
        : null;
    // const nav = accessRight.map(item => {
    //   return {
    //     name: item.label,
    //     url: item.value
    //   }
    // })
    const nav = generateNav(role || undefined);

    return (
      <div className="app">
        <AppHeader fixed>
          <Suspense fallback={this.loading()}>
            <Header onLogout={(e) => this.signOut(e)} />
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar display="lg">
            <div style={{ padding: "10px", backgroundColor: "#136AC3" }}>
              <img
                style={{ height: "100%", width: "100%" }}
                src="./img/MHCTransparentLogo.png"
              />
            </div>
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
              <AppSidebarNav
                navConfig={{
                  items: [...nav],
                }}
                {...this.props}
                router={router}
              />
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>

          <main className="main">
            {this.props.location.pathname !== Constant.ResetPasswordRequest &&
              this.props.location.pathname !==
                Constant.ResetPasswordRequestSuccess && (
                <AppBreadcrumb appRoutes={routes} router={router} />
              )}
            <Container fluid>
              <Suspense fallback={this.loading()}>
                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => <route.component {...props} />}
                      />
                    ) : null;
                  })}
                  <Redirect from="/" to="/search" />
                </Switch>
              </Suspense>
            </Container>
          </main>
        </div>
        {/* <AppFooter fixed>
          <Suspense fallback={this.loading()}>
            <Footer />
          </Suspense>
        </AppFooter> */}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      checkToken,
    },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(Main);
