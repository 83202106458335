export const ACTION_REQUEST = "ACTION_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const FORGOT_FAILURE = "FORGOT_FAILURE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_REQUEST_SUCCESS = "RESET_PASSWORD_REQUEST_SUCCESS";
export const RESET_PASSWORD_REQUEST_FAILURE = "RESET_PASSWORD_REQUEST_FAILURE";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAILURE = "VERIFY_FAILURE";
export const AUTHENTICATE_SSO_USER_REQUEST = "AUTHENTICATE_SSO_USER_REQUEST";
export const AUTHENTICATE_SSO_USER_SUCCESS = "AUTHENTICATE_SSO_USER_SUCCESS";
export const AUTHENTICATE_SSO_USER_FAILURE = "AUTHENTICATE_SSO_USER_FAILURE";
export const AUTHENTICATE_INTERNAL_USER_REQUEST =
  "AUTHENTICATE_INTERNAL_USER_REQUEST";
export const AUTHENTICATE_INTERNAL_USER_SUCCESS =
  "AUTHENTICATE_INTERNAL_USER_SUCCESS";
export const AUTHENTICATE_INTERNAL_USER_FAILURE =
  "AUTHENTICATE_INTERNAL_USER_FAILURE";
