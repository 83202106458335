import { connect } from "react-redux";
import Verification from "./Verification";
import { bindActionCreators } from "redux";
import { verifyAction } from "./AuthAction";

const mapStateToProps = (state, ownProps) => {
  return {
    payload: state.Auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Verification);
