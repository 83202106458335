import * as types from "./SearchActionTypes";

var initialState = {
  loading: false,
  result: [],
  resultAIA: null,
  resultAviva: null,
  resultClinic: null,
  isEligibleForMedicalChit: false,
  error: null,
  errorAIA: null,
  errorAviva: null,
  errorClinic: null
};

function SearchReducer(state = initialState, action) {
  switch (action.type) {
    case types.RESET_TABLE:
      return Object.assign({}, state, {
        result: [],
        loading: false,
        error: null,
      });
      break;

    case types.GET_MEMBER_AVIVA_REQUEST:
    case types.GET_MEMBER_AIA_REQUEST:
    case types.GET_MEMBER_REQUEST:
    case types.GET_CLINIC_REQUEST:
    case types.IS_ELIGIBLE_FOR_MEDICAL_CHIT_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        errorAIA: null,
        errorAviva: null,
        errorClinic: null
      });
      break;

    case types.GET_MEMBER_AVIVA_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        status: true,
        resultAviva: action.payload,
      });
      break;

    case types.GET_MEMBER_AIA_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        status: true,
        resultAIA: action.payload,
      });
      break;

    case types.GET_MEMBER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        status: true,
        result: action.payload,
      });
      break;

    case types.GET_CLINIC_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        status: true,
        resultClinic: action.payload,
      });
      break;
    case types.IS_ELIGIBLE_FOR_MEDICAL_CHIT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        status: true,
        isEligibleForMedicalChit: action.payload.eligible === true ? true : false,
      });
      break;
    case types.GET_MEMBER_AVIVA_FAILURE:
      return Object.assign({}, state, {
        result: [],
        resultAIA: null,
        loading: false,
        errorAviva: action.error,
      });
      break;

    case types.GET_MEMBER_AIA_FAILURE:
      return Object.assign({}, state, {
        result: [],
        resultAIA: null,
        loading: false,
        errorAIA: action.error,
      });
      break;

    case types.GET_MEMBER_FAILURE:
      return Object.assign({}, state, {
        result: [],
        resultAIA: null,
        loading: false,
        error: action.error,
      });
      break;

    case types.GET_CLINIC_FAILURE:
      return Object.assign({}, state, {
        result: [],
        resultAIA: null,
        loading: false,
        errorClinic: action.error,
      });
      break;
    case types.IS_ELIGIBLE_FOR_MEDICAL_CHIT_FAILURE:
      return Object.assign({}, state, {
        result: [],
        error: false,
        errorClinic: action.error,
      });
      break;
    default:
      return state;
  }

  return state;
}

export default SearchReducer;
