import React, { Component } from "react";
import * as Constant from "../../constant/general";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import sha256 from "sha256";
import Loadable from "react-loading-overlay";
import { FormInputError } from "../../components/FormInputError";

class Login extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: "",
      password: "",
      emailErrMsg: "",
      passwordErrMsg: "",
      errorMsg: "",
    };

    this.loginHandler = this.loginHandler.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }

  verifyForm() {
    this.setState({
      emailErrMsg: "",
      passwordErrMsg: "",
      errorMsg: "",
    });

    if (!this.state.email && !this.state.password) {
      this.setState({
        emailErrMsg: "Email cannot be empty",
        passwordErrMsg: "Password cannot be empty",
      });
    }

    if (!this.state.email) {
      this.setState({
        emailErrMsg: "Email cannot be empty",
      });

      return false;
    }

    if (!this.state.password) {
      this.setState({
        passwordErrMsg: "Password cannot be empty",
      });

      return false;
    }
    if (this.state.password.length < 8) {
      this.setState({
        passwordErrMsg: "Minimum password length is 8",
      });
      return false;
    }
    if (this.state.password.length > 20) {
      this.setState({
        passwordErrMsg: "Maximum password length is 20",
      });
      return false;
    }
    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    if (!regex.test(this.state.password)) {
      this.setState({
        passwordErrMsg:
          "The combination password must be at least 1 lowercase, 1 uppercase, 1 number & minimum 8 length characters",
      });
      return false;
    }

    return true;
  }

  loginHandler() {
    if (this.verifyForm()) {
      this.props
        .loginAction(this.state.email, sha256(this.state.password))
        .then(() => {
          if (this.props.payload.status && this.props.payload.result.useOtp) {
            this.props.history.push(Constant.Verification);
          } else if (
            this.props.payload.status &&
            !this.props.payload.result.useOtp
          ) {
            this.props.history.push(Constant.Search);
          } else {
            this.setState({
              errorMsg: this.props.payload.error.message,
            });
          }
        });
    }
  }

  keyPress(event) {
    if (event.key === "Enter") {
      this.loginHandler();
    }
  }
  render() {
    return (
      <Loadable active={this.props.payload.loading} spinner text="Loading...">
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="8">
                <CardGroup>
                  <Card
                    className="text-white bg-info py-4 d-md-down-none"
                    style={{ width: "44%" }}
                  >
                    <CardBody className="text-left">
                      <div>
                        <h1>Booking Admin Dashboard</h1>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="p-4">
                    <CardBody>
                      <Form>
                        <h1>Login</h1>
                        <p className="text-muted">Sign in to proceed</p>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-user"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="email"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={(event) =>
                              this.setState({
                                email: event.target.value,
                              })
                            }
                            onKeyPress={this.keyPress}
                          />
                        </InputGroup>
                        <FormInputError text={this.state.emailErrMsg} />
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            style={
                              this.state.passwordErrMsg
                                ? {
                                    borderColor: "red",
                                  }
                                : null
                            }
                            type="password"
                            value={this.state.password}
                            placeholder="Password"
                            onChange={(event) =>
                              this.setState({
                                password: event.target.value,
                              })
                            }
                            onKeyPress={this.keyPress}
                          />
                        </InputGroup>
                        <FormInputError text={this.state.passwordErrMsg} />
                        <FormInputError
                          text={this.state.errorMsg}
                          fontSize="15px"
                        />
                        <Row>
                          <Col xs="6">
                            <Button
                              color="info"
                              className="px-4"
                              onClick={this.loginHandler}
                              onKeyPress={this.keyPress}
                            >
                              Login
                            </Button>
                          </Col>
                          <Col xs="6" className="text-right">
                            <Button
                              color="link"
                              className="px-0"
                              onClick={() =>
                                this.props.history.push(Constant.ForgotPassword)
                              }
                            >
                              Forgot password?
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
            <br />
            <Row className="justify-content-center">
              <h6 style={{ fontSize: 12 }}>
                Having trouble logging in?
                <font color="#21A8D8"> Contact us at 6305 4578</font>
              </h6>
            </Row>
          </Container>
        </div>
      </Loadable>
    );
  }
}

export default Login;
