import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ExternalLogin from "./ExternalLogin";
import { authenticateInternal } from "./AuthAction";

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.Auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      authenticateInternal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalLogin);
