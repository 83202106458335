import React, { Component } from "react";
import {
  Alert,
  Badge,
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Button,
  InputGroup,
  Input,
  InputGroupText,
  InputGroupAddon,
  FormGroup,
  Label,
} from "reactstrap";

import swal from "sweetalert";
import moment from "moment";
import DatePicker from "react-datepicker";
import Loadable from "react-loading-overlay";
import ReactJson from "react-json-view";

class MemberDetailAIA extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchNRIC: "",
      searchName: "",
      date: moment().format("YYYY-MM-DD"),
      item: null,
      jsonResult: null,
      // dictionary : {
      //   AIA : {
      //     "polno" : "Policy No",
      //     "suboffcode": "Sub Off Code",
      //     "cardtype" : "IEC Card Type",
      //     "system" : "System",
      //     "relationship" : "Employee/Dependant",
      //     "fullname": "Name",
      //     "nric" : "NRIC",
      //     "memid" :"Member ID",
      //     "empfullname" : "Employee Name",
      //     "nric" : "Employee NRIC",
      //     "empmemid" : "Employee Member ID",
      //     "dob" : "DOB",
      //     "gender" : "Gender",
      //     "suboffname":"Sub Off Name",
      //     "": "IEC Policy Start"


      //   }
      // }
    };


  }

  onInputChangeHandler(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  componentDidMount() {
    const { error: errorAIA, result } = this.props.search;
    // console.log(this.props, "==PROPS")
    if (errorAIA) {
      console.log("error.code", errorAIA.code);
      swal({
        title: `${errorAIA.title} ${errorAIA.code}`,
        text: JSON.stringify(errorAIA.message),
        icon: "error",
        // timer: 1000,
      });
      return;
    }
    const resultAIA = result.memberDetail

    if (resultAIA.memberenquiry.return.code == "001") {
      // console.log("item", resultAIA.memberenquiry.resultset.item[0]);
      let item = resultAIA.memberenquiry.resultset.item;

      if (Array.isArray(item)) {
        item = item[0];
      }

      this.setState({
        jsonResult: resultAIA,
        item: item,
      });
      return;
    } else if (resultAIA.memberenquiry.return.code == "003") {
      swal({
        title: resultAIA.memberenquiry.return.description,
        text:
          "Member terminate date: " +
          resultAIA.memberenquiry.return.memberterminateddate,
        icon: "error",
        // timer: 1000,
      });
    } else if (resultAIA.memberenquiry.return.code == "004") {
      swal({
        title: resultAIA.memberenquiry.return.description,
        text:
          "Product terminate date: " +
          resultAIA.memberenquiry.return.productterminateddate,
        icon: "error",
        // timer: 1000,
      });
    } else {
      swal({
        title: resultAIA.memberenquiry.return.description,
        text: "",
        icon: "error",
        // timer: 1000,
      });
    }
    this.setState({
      item: null,
      jsonResult: null,
    });
  }

  render() {


    let system = "";

    let item = {};
    if (this.state.item) {
      item = this.state.item;
    }
    if (item && item.cardtype) {
      system = process.env.WILLIAM_SYSTEM_AIA;
      if (item.cardtype == "B") {
        system = process.env.WILLIAM_SYSTEM_MHC;
      }
    }

    return (
      <React.Fragment>
        <div className="animated fadeIn">
          <Loadable
            active={this.props.search.loading}
            spinner
            text="Loading..."
          >
            <Row>
              <Card style={{ width: "100%" }}>
                <CardHeader>
                  {`${this.props.searchProgram}`} Member Detail
                  {this.props.hideSearch ? "": <Row>
                    <Col sm="4">
                      <DatePicker
                        selected={
                          moment(this.state.date, "YYYY-MM-DD").isValid()
                            ? moment(this.state.date, "YYYY-MM-DD").toDate()
                            : undefined
                        }
                        onSelect={(date) => {
                          let event = {
                            target: {
                              name: "date",
                              value: date
                                ? moment(date).format("YYYY-MM-DD")
                                : "",
                            },
                          };
                          this.onInputChangeHandler(event);
                        }}
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        customInput={
                          <FormGroup>
                            <Label>Visit Date</Label>
                            <InputGroup>
                              <Input
                                style={
                                  this.state.dateErrorMessage
                                    ? {
                                        borderColor: "red",
                                      }
                                    : null
                                }
                                autoComplete="off"
                                placeholder="YYYY-MM-DD"
                                value={this.state.date ? this.state.date : ""}
                                name="date"
                                onChange={(event) =>
                                  this.onInputChangeHandler(event)
                                }
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fa fa-calendar"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                            {this.state.dateErrorMessage ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                }}
                              >
                                {this.state.dateErrorMessage}
                              </p>
                            ) : null}
                          </FormGroup>
                        }
                      />
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label>NRIC / Member ID</Label>
                        <InputGroup>
                          <Input
                            onKeyPress={this.handleKeyNricPress.bind(this)}
                            type="text"
                            name="searchNRIC"
                            onChange={(e) => this.onInputChangeHandler(e)}
                            id="searchNRIC"
                            value={this.state.searchNRIC}
                            required
                            placeholder="NRIC / Member ID"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="1">
                      <Label>&nbsp;</Label>
                      <InputGroup>
                        <Button
                          color="primary"
                          onClick={() => this.onSearchNRICButtonClicked()}
                        >
                          Search
                        </Button>
                      </InputGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label>&nbsp;</Label>
                        <InputGroup>
                          <Button
                            onClick={() => this.clearAll()}
                            type="submit"
                            color="primary"
                          >
                            Clear All
                          </Button>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>}
                  {/* <br /> */}
                </CardHeader>
                <CardBody>
                  <table
                    width="100%"
                    border="0"
                    cellspacing="2"
                    cellpadding="2"
                  >
                    <col width="200px" />
                    <col width="auto" />
                    <tr>
                      <td className="table-left">Policy No</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.polno }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Sub Off Code </td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.suboffcode }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">IEC Card Type </td>
                      <td className="table-right">
                        {item.cardtype
                          ? item.cardtype == "B"
                            ? "BLUE CARD"
                            : "RED CARD"
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-left">System</td>
                      <td className="table-right">
                        {system && <a href={system}>{system}</a>}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-left">Employee/Dependant </td>
                      <td className="table-right">
                        {item.relationship
                          ? item.relationship == "M"
                            ? "Member"
                            : "Dependant"
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-left">Name</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html: item.firstname
                            ? item.firstname + " " + item.lastname
                            : "",
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">NRIC</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.nric }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Member ID</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.memid }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Employee Name</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html: item.empfirstname
                            ? item.empfirstname + " " + item.emplastname
                            : "",
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Employee NRIC</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.empnric }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Employee Member ID</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.empmemid }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">DOB</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.dob }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Gender</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.gender }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Sub Off Name </td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.suboffname }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">IEC Policy Start </td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.poldtfr }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">IEC Policy End </td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.poldtto }}
                      ></td>
                    </tr>
                    {/* <tr>
                      <td className="table-left">Benefit Code </td>
                      <td className="table-right" dangerouslySetInnerHTML={{ __html: item.aaaa }}></td>
                    </tr>
                    <tr>
                      <td className="table-left">Network</td>
                      <td className="table-right" dangerouslySetInnerHTML={{ __html: item.aaaa }}></td>
                    </tr> */}
                    <tr>
                      <td className="table-left">Sub Off Address 1 </td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html: item.suboffaddress1,
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Sub Off Address 2</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html: item.suboffaddress2,
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Sub Off Address 3 </td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html: item.suboffaddress3,
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Sub Off State </td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.suboffstate }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Contact Person </td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.contperson }}
                      ></td>
                    </tr>
                  </table>
                  <br />
                  {this.state.jsonResult && (
                    <ReactJson
                      name={false}
                      enableClipboard={false}
                      displayObjectSize={false}
                      displayDataTypes={false}
                      src={this.state.jsonResult}
                    />
                  )}
                </CardBody>
              </Card>
            </Row>
          </Loadable>
        </div>
      </React.Fragment>
    );
  }
}

export default MemberDetailAIA;
