import React, { Component } from "react";

import LogHistoryComponent from "../../components/LogHistoryComponent";
import swal2 from "sweetalert2";

class AppAccessLogs extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };

  }

  componentDidMount() {
    console.log("HOLLAAA");
    const error = this.props.appAccessLogs.error
    if (error) {
      return swal2.fire({
        type: "error",
        title: `${error.title} ${error.code}`,
        text: error.message,
        html: error.message,
        icon: "error",
        confirmButtonColor: "#136AC3",
        // timer: 1000,
      })

    }
  }

  render() {
    const columns = [
      {
        header: "User",
        id: "userID",
        filterable: true,
        sortable: false
      },
      {
        header: "Role",
        id: "userRole",
        filterable: true,
        sortable: false,
      },
      {
        header: "Url",
        id: "redirectUrl",
        filterable: false,
        sortable: false,
      },
      {
        header: "Metadata",
        id: "metadata",
        filterable: false,
        sortable: false,
      },
      {
        header: "Timestamp",
        id: "createdAt",
        filterable: true,
        filterCustom: "dateRange",
        sortable: false,
      },
      {
        header: "Remark",
        id: "remark",
        filterable: true,
        sortable: false,
      }
    ];
    return (
      <React.Fragment>
        
        <LogHistoryComponent
          headerText={"App Access Logs"}
          columns={columns}
          payload={this.props.appAccessLogs.result}
          getLogs={this.props.getLogs}
          exportData={this.props.exportData}
          error={this.props.appAccessLogs.error}
        />
      </React.Fragment>
    );
  }
}

export default AppAccessLogs;
