import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Alert
} from "reactstrap";

import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import * as Constant from "./../../constant/general";

import LogHistoryComponent from "../../components/LogHistoryComponent";

class SearchLogs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      
    };


  }

  componentWillUnmount() {
    const error = this.props.searchLogs.error
    if (error) {
      return swal2.fire({
        type: "error",
        title: `${error.title} ${error.code}`,
        text: error.message,
        html: error.message,
        icon: "error",
        confirmButtonColor: "#136AC3",
        // timer: 1000,
      })

    }
  }

  render() {
    // console.log("PROPS", this.props);
    // console.log("STATE", this.state);
    const columns = [
      {
        header: "Program",
        id: "program",
        filterable: true,
        sortable: false
      },
      {
        header: "User",
        id: "userID",        
        filterable: true,
        sortable: false,
      },
      {
        header: "Role",
        id: "userRole",
        filterable: true,
        sortable: false,
      },
      {
        header: "Metadata",
        id: "metadata",
        filterable: false,
        sortable: false,
      },
      {
        header: "Timestamp",
        id: "createdAt",
        filterable: true,
        filterCustom: "dateRange",
        sortable: false,
      },
      {
        header: "Remark",
        id: "remark",
        filterable: true,
        sortable: false,
      }
    ];
    return (
      <React.Fragment>
       <LogHistoryComponent
          headerText={"Search Member Logs"}
          columns={columns}
          loading={this.props.searchLogs.loading}
          payload={this.props.searchLogs.result || []}
          getLogs={this.props.getLogs}
          exportData={this.props.exportData}
          error={this.props.searchLogs.error || null}
        />
        
      </React.Fragment>
    );
  }
}

export default SearchLogs;
