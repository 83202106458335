import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactLoading from "react-loading";

class Loading extends Component {
  render() {
    return (
      <div style={{ width: "100%", marginBottom: "40px" }}>
        <ReactLoading
          type={"spin"}
          color={"#333"}
          height={"50px"}
          width={"50px"}
          className="m-auto"
        />
      </div>
    );
  }
}

let wrapper = document.createElement("div");
ReactDOM.render(<Loading />, wrapper);
let SwalLoading = wrapper.firstChild;

export default SwalLoading;
