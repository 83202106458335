import React from 'react';
import {
    Badge,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardHeader,
    CardBody,
    Col,
    Row,
    UncontrolledTooltip,
    Table
} from 'reactstrap';
import { CompoDropdown } from "../../components/FormComponents";


import ReactTable from "react-table";
import "react-table/react-table.css";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
const ReactTableFixedCol = withFixedColumns(ReactTable);

class SelectVisit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1,
            modal: false,
            clinicSelected: null
        };

        this.toggle = this.toggle.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.onChangeField = this.onChangeField.bind(this);
        this.onClickAddClaim = this.onClickAddClaim.bind(this);

    }
    componentDidMount() {
        // console.log(this.props, "==PROPS")

        // this.setState({
        //   item: null,
        //   jsonResult: null,
        // });
    }

    onClickAddClaim() {
        const { cid, coc, did, eid, ws, sd } = this.props.data;
        if (this.props.isClinic === false && this.props.data) {
            this.props.getVisitHistory(did || eid, sd, ws)
            this.setState(prevState => ({
                modal: !prevState.modal
            }));
        }
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));

    }
    onChangeField(fieldKey, event, item) {
        this.setState(prevState => ({
            clinicSelected: event
        }));
    }
    toggleTab(value) {
        this.setState(prevState => {
            if (prevState.tab !== value) {
                return {
                    tab: value
                }
            }
        })
    }


    render() {
        // console.log(this.props, "PROPS SELECT VISIT");
        const { visitHistory, data } = this.props;
        // console.log(visitHistory.length, "LENGTH")
        const columns = [
            {
                Header: "Visit Number",
                id: "vn",
                accessor: (d) => {
                    return d.vn;
                },
                filterable: true,
                sortable: false,
                filterMethod: (filter, row) =>
                    row[filter.id]
                        ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
                        : null,
                style: {},
            },
            {
                Header: "Visit Date",
                id: "vd",
                accessor: (d) => {
                    return d.vd;
                },
                filterable: true,
                sortable: false,
                filterMethod: (filter, row) =>
                    row[filter.id]
                        ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
                        : null,
                style: {},
            },
            {
                Header: "Clinic Code",
                id: "cc",
                accessor: (d) => {
                    return d.cc;
                },
                filterable: true,
                sortable: false,
                filterMethod: (filter, row) =>
                    row[filter.id]
                        ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
                        : null,
                style: {},
            },
            {
                Header: "Clinic Name",
                id: "cn",
                accessor: (d) => {
                    return d.cn
                },
                filterable: true,
                sortable: false,
                filterMethod: (filter, row) =>
                    row[filter.id]
                        ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
                        : null,
                style: {},
            },


            {
                Header: "Action",
                id: "action",
                accessor: (d) => {
                    return (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <Button
                                        color='primary'
                                        onClick={(e) => {
                                            this.props.onSubmitAddVisit(d.cc, "creditNote", data.did || data.eid, null, data.ws, data.isIEC, data.sd, data.td, data.cid, { cc: d.cc, cid: data.cid, coc: data.coc, gc: data.gc, vc: d.vn, vd: d.vd })

                                        }}
                                    // disabled={this.props.dropdownOptions && this.props.dropdownOptions.length > 0 ? false : true}
                                    >Select
                                    </Button>

                                </Col>


                            </Row>
                            {/* <Button
                    color="primary"
                    className="mb-2"
                    onClick={() => console.log('Add Normal visit')}
                    block
                  >
                    Add Normal visit
                  </Button> */}

                        </React.Fragment>
                    )
                },
                filterable: false,
                sortable: false,
                style: {},
                width: 150,
            }
        ];

        return (
            <div>
                <Button
                    outline={this.props.buttonOutline}
                    block={this.props.buttonBlock}
                    color={this.props.buttonColor || 'info'}
                    className={this.props.buttonClass}
                    onClick={this.onClickAddClaim}
                    style={{ width: 170 }}
                >
                    {this.props.buttonLabel}
                </Button>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    size='lg'
                // centered
                >
                    <ModalHeader>{this.props.buttonLabel}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <ReactTable
                                    className="-striped -highlight"
                                    columns={columns}
                                    noDataText="No data found!"
                                    data={visitHistory || []}
                                    defaultPageSize={10}
                                    pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                                    getTdProps={() => ({ style: { textAlign: "center" } })}
                                    loading={this.props.isLoading === true ? true : false}
                                />

                                {/* <Table bordered>
                                    <thead>
                                        <tr>
                                            <th>Visit Number</th>
                                            <th>Visit Date</th>
                                            <th>Clinic Code</th>
                                            <th>Clinic Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        {visitHistory && visitHistory.length > 0 ? visitHistory.map((item, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>{item.vn}</td>
                                                    <td>{item.vd}</td>
                                                    <td>{item.cc}</td>
                                                    <td>{item.cn}</td>
                                                    <td>
                                                        <Button
                                                            color='primary'
                                                            onClick={(e) => {
                                                                this.props.onSubmitAddVisit(item.cc, "creditNote", data.did || data.eid, null, data.ws, data.isIEC, { cc: item.cc, cid: data.cid, coc: data.coc, gc: data.gc, vc: item.vn })

                                                            }}
                                                        // disabled={this.props.dropdownOptions && this.props.dropdownOptions.length > 0 ? false : true}
                                                        >Select
                                                        </Button>
                                                    </td>
                                                </tr>)
                                        }) : "No visit found"}


                                    </tbody>
                                </Table> */}
                            </Col>
                        </Row>
                        <Row>
                            {/* <Col>
                                <Button
                                    color='primary'
                                    onClick={this.onSubmit}
                                    disabled={this.props.dropdownOptions && this.props.dropdownOptions.length > 0 ? false : true}
                                >Go to William System
                                </Button>
                            </Col> */}
                        </Row>
                    </ModalBody>
                </Modal>
            </div >
        );
    }
}

export default SelectVisit;
