import React from "react";

export const FormInputError = ({ text, fontSize }) => {
  if (text) {
    return (
      <p style={{ color: "red", fontSize: `${fontSize || "10px"}` }}>{text}</p>
    );
  }
  return null;
};
