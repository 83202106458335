import axios from "axios";
// import CustomFormData from '../../utility/CustomFormData';
import AlertError from "../../utility/AlertError";
import * as types from "./AddVisitActionTypes";
import * as Constant from "../../constant/general";
const BASE_URL = process.env.BASE_URL;

// ADD VISIT PAE
export function getAddVisitUrl(userId, visitType, patientId, visitDate, portal, isIEC, sd, td, cid) {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_ADD_VISIT_URL_REQUEST });

    var user = getState().Auth.result;

    await axios({
      method: "POST",
      url: "/v1/williamSystem/addVisitPage",
      data: {
        userId: userId,
        visitType: visitType,
        patientId: patientId,
        visitDate: visitDate,
        portal: portal,
        isIEC: isIEC,
        sd, td, cid
      },
      headers: {
        authorization: user.access_token,
      },
      
    }).then((response) => {
      if (response.data && response.data.result) {
        dispatch({
          type: types.GET_ADD_VISIT_URL_SUCCESS,
          payload: response.data.result,
        });
      } else {
        var err_msg =
          response.response.data.error || response.response.data.message;
        let alertError = new AlertError(
          response.response.status,
          "Error",
          err_msg
        );
        dispatch({
          type: types.GET_ADD_VISIT_URL_FAILURE,
          error: alertError,
        });
        if (response.response.status == 401) {
          localStorage.removeItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
        }
      }
    });
  };
}

// DETAIL VISIT PAGE
export function getVisitUrl(userId, visitNo, portal) {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_VISIT_DETAIL_URL_REQUEST });

    var user = getState().Auth.result;

    await axios({
      method: "POST",
      url: "/v1/williamSystem/detailVisitPage",
      data: {
        userId: userId,
        visitNo: visitNo,
        portal: portal
      },
      headers: {
        authorization: user.access_token,
      },
      
    }).then((response) => {
      if (response.data && response.data.result) {
        dispatch({
          type: types.GET_VISIT_DETAIL_URL_SUCCESS,
          payload: response.data.result,
        });
      } else {
        var err_msg =
          response.response.data.error || response.response.data.message;
        let alertError = new AlertError(
          response.response.status,
          "Error",
          err_msg
        );
        dispatch({
          type: types.GET_VISIT_DETAIL_URL_FAILURE,
          error: alertError,
        });
        if (response.response.status == 401) {
          localStorage.removeItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
        }
      }
    });
  };
}

// ADD CREDIT NOTE
export function getAddCreditNoteBillingUrl(data) {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_ADD_CREDIT_NOTE_BILLING_URL_REQUEST });

    var user = getState().Auth.result;

    await axios({
      method: "POST",
      url: "/v1/other/addCreditNote",
      data: data,
      headers: {
        authorization: user.access_token,
      },
      
    }).then((response) => {
      if (response.data && response.data.result) {
        dispatch({
          type: types.GET_ADD_CREDIT_NOTE_BILLING_URL_SUCCESS,
          payload: response.data.result,
        });
      } else {
        var err_msg =
          response.response.data.error || response.response.data.message;
        let alertError = new AlertError(
          response.response.status,
          "Error",
          err_msg
        );
        dispatch({
          type: types.GET_ADD_CREDIT_NOTE_BILLING_URL_FAILURE,
          error: alertError,
        });
        if (response.response.status == 401) {
          localStorage.removeItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
        }
      }
    });
  };
}

// VISIT HISTORY
export function getVisitHistory(patientId, sd, portal) {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_VISIT_HISTORY_REQUEST });

    var user = getState().Auth.result;

    await axios({
      method: "POST",
      url: "/v1/williamSystem/visitHistory",
      data: {
        patientId,
        sd,
        portal: portal
      },
      headers: {
        authorization: user.access_token,
      },
      
    }).then((response) => {
      if (response.data && response.data.result) {
        dispatch({
          type: types.GET_VISIT_HISTORY_SUCCESS,
          payload: response.data.result,
        });
      } else {
        var err_msg =
          response.response.data.error || response.response.data.message;
        let alertError = new AlertError(
          response.response.status,
          "Error",
          err_msg
        );
        dispatch({
          type: types.GET_VISIT_HISTORY_FAILURE,
          error: alertError,
        });
        if (response.response.status == 401) {
          localStorage.removeItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
        }
      }
    });
  };
}