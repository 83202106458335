import axios from "axios";
// import CustomFormData from '../../utility/CustomFormData';
import AlertError from "../../utility/AlertError";
import * as types from "./AuthActionTypes";
import * as Constant from "../../constant/general";
import swal from "sweetalert";
const BASE_URL = process.env.BASE_URL;
const LOGIN_SESSION = "loginSession";
export function authenticateSSOUser(app_salt, sid, url) {
  return async (dispatch) => {
    dispatch({ type: types.AUTHENTICATE_SSO_USER_REQUEST });

    await axios({
      method: "GET",
      url: `${process.env.SSO_API_URL}/api/v1/user/authenticate?app_salt=${app_salt}&session_id=${sid}`,
    })
      .then((res) => {
        if (res.status === 200) {
          let result = {
            data: res.data.data,
            email: res.data.data.user_name,
            name: res.data.data.full_name,
            token: res.data.data.access_token,
            access_token: res.data.data.access_token,
            // role: res.data.data.meta_data.role_type[0].name,
            // roleLevel: res.data.data.meta_data.role_type[0].level,
            // accessRight: res.data.data.meta_data.role_type[0].accessRight
          };

          savingLoginSession(result);
          dispatch({
            type: types.AUTHENTICATE_SSO_USER_SUCCESS,
            payload: res.data.data,
          });
        } else {
          localStorage.removeItem("loginSession");
          localStorage.removeItem("sid");
          localStorage.removeItem("salt");
          let app_salt = process.env.SSO_APP_SALT;
          let url = encodeURIComponent(window.location);
          swal("Token Expired", "Please log in again", "info").then(() => {
            window.location.assign(
              `${process.env.SSO_URL}/login?redirect=${url}&salt=${app_salt}`
            );
          });
        }
      })
      .catch((err) => {
        dispatch({ type: types.AUTHENTICATE_SSO_USER_FAILURE });
        window.location.assign(
          `${process.env.SSO_URL}/login?redirect=${url}&salt=${app_salt}`
        );
      });
  };
}

export function authenticateInternal(sessionId) {
  return async (dispatch) => {
    dispatch({ type: types.AUTHENTICATE_INTERNAL_USER_REQUEST });

    await axios({
      method: "GET",
      url: `/v1/auth/getSession?sessionId=${sessionId}`,
      headers: {
        authorization: process.env.EXTERNAL_API_KEY,
      },
    }).then(function (response) {
      // console.log(response, '====>this is response from action');
      if (response.data && response.data.success) {
        // console.log('response success true');
        let result = {
          user_name: response.data.data.username,
          access_token: response.data.data.token,
          meta_data: {
            role_types: [
              {
                id: "",
                name: response.data.data.role,
                default: true,
              },
            ],
          },
          email: "",
          name: response.data.data.name,
          token: response.data.data.token,
        };
        savingLoginSession(result);
        localStorage.setItem("externalLogin", "true");
        dispatch({
          type: types.AUTHENTICATE_INTERNAL_USER_SUCCESS,
          payload: result,
        });
      } else {
        let err_msg =
          "Error: " + response.response.data.error ||
          response.response.data.message;
        let alertError = new AlertError(500, "Error", err_msg);
        dispatch({
          type: types.AUTHENTICATE_INTERNAL_USER_FAILURE,
          error: alertError,
        });
      }
    });
  };
}

export function authenticateAction() {
  return (dispatch) => {
    if (accountLoggedIn()) {
      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: JSON.parse(
          localStorage.getItem(Constant.LOCAL_STORAGE_LOGIN_SESSION)
        ),
      });
      return true;
    }
    return false;
  };
}

export function accountLoggedIn() {
  return localStorage.getItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
}

export function getLoginUserRole() {
  var loginUserRole = "";
  var loginSession = localStorage.getItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
  if (loginSession) {
    loginSession = JSON.parse(loginSession);
    if (loginSession.user && loginSession.user.role) {
      loginUserRole = loginSession.user.role;
    }
  }

  return loginUserRole;
}

export function logoutAction() {
  return function (dispatch, state) {
    localStorage.removeItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
    dispatch({ type: types.LOGOUT });
  };
}

// For login action
export function loginAction(email, password) {
  return async (dispatch) => {
    dispatch({ type: types.ACTION_REQUEST });
    let form = {
      email: email,
      password: password,
    };
    await axios({
      method: "POST",
      url: "/v1/auth",
      data: JSON.stringify(form),
    }).then(function (response) {
      // console.log(response, '====>this is response from action');
      if (response.data && response.data.success) {
        // console.log('response success true');
        let result = {
          name: response.data.result.name,
          email: email,
          token: response.data.result.token,
          role: response.data.result.role,
          accessRight: response.data.result.accessRight,
        };
        savingLoginSession(result);
        dispatch({
          type: types.LOGIN_SUCCESS,
          payload: response.data.result,
        });
      } else {
        let err_msg =
          "Error: " + response.response.data.error ||
          response.response.data.message;
        let alertError = new AlertError(500, "Error", err_msg);
        dispatch({
          type: types.LOGIN_FAILURE,
          error: alertError,
        });
      }
    });
  };
}

// For verify action
export function verifyAction(email, otp) {
  return async (dispatch) => {
    dispatch({ type: types.ACTION_REQUEST });
    let form = {
      email: email,
      otp: otp,
    };
    await axios({
      method: "POST",
      url: "/v1/auth/verify",
      data: JSON.stringify(form),
    }).then(function (response) {
      if (response.data && response.data.success) {
        let result = JSON.parse(
          localStorage.getItem(Constant.LOCAL_STORAGE_LOGIN_SESSION)
        );
        result.token = response.data.token;
        dispatch({
          type: types.VERIFY_SUCCESS,
          payload: response.data,
        });
      } else {
        // let err_msg = 'Error' + response.response.data.error;
        // let alertError = new AlertError(500, 'Error', err_msg);
        dispatch({
          type: types.VERIFY_FAILURE,
          payload: response.response.data,
        });
        // return false;
      }
    });
  };
}

export function requestResetPassword(params) {
  return async (dispatch) => {
    dispatch({ type: types.ACTION_REQUEST });

    await axios({
      method: "post",
      url: "/v1/user/requestResetPassword",
      data: JSON.stringify(params),
    }).then(function (response) {
      if (response.data && response.data.success) {
        dispatch({
          type: types.RESET_PASSWORD_REQUEST_SUCCESS,
          payload: response.data,
        });
      } else {
        //var err_msg = 'Forgot Password Failed. ' + response.data.error;
        var err_msg = "Error: " + response.response.data.message;
        let alertError = new AlertError(500, "Error", err_msg);
        dispatch({
          type: types.RESET_PASSWORD_REQUEST_FAILURE,
          error: alertError,
        });
      }
    });
  };
}

export function verifyResetPasswordToken(token) {
  return async (dispatch) => {
    dispatch({ type: types.ACTION_REQUEST });
    await axios({
      method: "post",
      url: "/v1/user/verifyResetPasswordToken",
      data: JSON.stringify({ token }),
    }).then(function (response) {
      // console.log(response.response, '====>this is response from action');
      if (response.data && response.data.success) {
        dispatch({
          type: types.RESET_PASSWORD_SUCCESS,
          payload: response.data,
        });
      } else {
        // var err_msg = 'Forgot Password Failed. ' + response.data.error;
        var err_msg =
          "Error: " + response.response.data.error ||
          response.response.data.message;
        let alertError = new AlertError(500, "Error", err_msg);
        dispatch({ type: types.RESET_PASSWORD_FAILURE, error: alertError });
      }
    });
  };
}

export function resetPasswordAction(params) {
  return async (dispatch) => {
    dispatch({ type: types.ACTION_REQUEST });

    await axios({
      method: "post",
      url: "/v1/user/resetPassword",
      data: JSON.stringify(params),
    }).then(function (response) {
      if (response.data && response.data.success) {
        dispatch({
          type: types.RESET_PASSWORD_SUCCESS,
          payload: response.data,
        });
      } else {
        //var err_msg = 'Forgot Password Failed. ' + response.data.error;
        var err_msg =
          "Error: " + response.response.data.error ||
          response.response.data.message;
        let alertError = new AlertError(500, "Error", err_msg);
        dispatch({
          type: types.RESET_PASSWORD_FAILURE,
          payload: response.response.data,
          error: alertError,
        });
      }
    });
  };
}

export function checkToken() {
  return async (dispatch, getState) => {
    let loginSession = JSON.parse(localStorage.getItem("loginSession"));

    let token = loginSession.token;
    await axios({
      method: "POST",
      headers: { Authorization: token },
      url: `api/v1/auth/checkToken`,
    })
      .then((res) => {
        if (!res.data.success) {
          localStorage.removeItem(LOGIN_SESSION);
          localStorage.removeItem("sid");
          localStorage.removeItem("salt");
          swal("Token Expired", "Please log in again", "info").then(() => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.removeItem(LOGIN_SESSION);
        localStorage.removeItem("sid");
        localStorage.removeItem("salt");
        swal("Token Expired", `Please log in again`, "info").then(() => {
          window.location.reload();
        });
      });
  };
}

function savingLoginSession(loginSession) {
  localStorage.setItem(
    Constant.LOCAL_STORAGE_LOGIN_SESSION,
    JSON.stringify(loginSession)
  );
}

//Store User Authenticate
export function store_authenticateSSOUser(app_salt, sid, url) {
  return async (dispatch) => {
    dispatch({ type: types.AUTHENTICATE_SSO_USER_REQUEST });

    await axios({
      method: "GET",
      url: `${process.env.SSO_API_URL}/api/v1/user/authenticate?app_salt=${app_salt}&session_id=${sid}`,
    })
      .then((res) => {
        if (res.status === 200) {
          let result = {
            data: res.data.data,
            email: res.data.data.user_name,
            name: res.data.data.full_name,
            token: res.data.data.access_token,
            url: url,
            // role: res.data.data.meta_data.role_type[0].name,
            // roleLevel: res.data.data.meta_data.role_type[0].level,
            // accessRight: res.data.data.meta_data.role_type[0].accessRight
          };

          store_savingLoginSession(result);
          dispatch({
            type: types.AUTHENTICATE_SSO_USER_SUCCESS,
            payload: res.data.data,
          });
        } else {
          localStorage.removeItem("store_loginSession");
          localStorage.removeItem("s_sid");
          localStorage.removeItem("s_salt");
          let app_salt = process.env.SSO_APP_SALT;
          let url = encodeURIComponent(window.location);
          url = url.replace("%23", "hash");
          swal("Token Expired", "Please log in again", "info").then(() => {
            window.location.assign(
              `${process.env.SSO_URL}/login?redirect=${url}&salt=${app_salt}`
            );
          });
        }
      })
      .catch((err) => {
        dispatch({ type: types.AUTHENTICATE_SSO_USER_FAILURE });
        window.location.assign(
          `${process.env.SSO_URL}/login?redirect=${url}&salt=${app_salt}`
        );
      });
  };
}

export function store_logoutAction() {
  return async (dispatch, state) => {
    localStorage.removeItem(Constant.STORE_LOCAL_STORAGE_LOGIN_SESSION);
    localStorage.removeItem("s_sid");
    localStorage.removeItem("s_salt");
    dispatch({ type: types.LOGOUT });
  };
}

function store_savingLoginSession(store_loginSession) {
  localStorage.setItem(
    Constant.STORE_LOCAL_STORAGE_LOGIN_SESSION,
    JSON.stringify(store_loginSession)
  );
}
