import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  FormText,
  CustomInput,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  ButtonGroup,
  Alert,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import Loadable from "react-loading-overlay";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
// import CreatableSelect from 'react-select/lib/Creatable';
import { FormInputError } from "./FormInputError";
import moment from "moment";

export const TextIndicator = ({ text, fontSize, ...rest }) => {
  if (text) {
    return (
      <p style={{ fontSize: `${fontSize || "14px"}` }} {...rest}>
        <i className="fas fa-flag"></i> {text}
      </p>
    );
  }
  return null;
};

export const CardSection = function ({ title, children, md, sm, xs }) {
  return (
    <Col md={md} sm={sm} xs={xs} className="mb-4">
      <Card className="h-100">
        <CardHeader style={{ backgroundColor: "#E2F3FF" }}>{title}</CardHeader>
        <CardBody>{children}</CardBody>
      </Card>
    </Col>
  );
};

export const CompoFileInput = function ({
  fieldKey,
  item,
  disabled,
  onChangeField,
  file,
}) {
  const [value, setValue] = React.useState("");
  const defaultInput = React.useRef();

  return (
    <FormGroup>
      <Label className="d-block">{item.title}</Label>

      <Input
        type="file"
        style={{ display: "none" }}
        placeholder={item.placeholder ? item.placeholder : ""}
        readOnly={item.readOnly}
        disabled={disabled}
        innerRef={defaultInput}
        value={value}
        accept=".pdf"
        onChange={(event) => {
          setValue(event.target.value);
          onChangeField(fieldKey, event, item);
        }}
      />
      <Row className="ml-1">
        <Button
          className="mr-2"
          color="primary"
          onClick={() => {
            defaultInput.current.click();
          }}
          disabled={disabled}
        >
          Choose File
        </Button>
        {/* {item.value ?
        <Button 
          color="link"
          onClick={() => {
            if(typeof item.value === 'string'){
              let pdfWindow = window.open("");
              pdfWindow.document.write(`<iframe width='100%' height='100%' src='data:application/pdf;base64, ${encodeURI(item.value)}'></iframe>`);
            }else if(typeof item.value === 'object'){
              var reader = new FileReader();
              reader.readAsDataURL(item.value);
              reader.onload = function () {
                let pdfWindow = window.open("");
                console.log(reader.result)
                pdfWindow.document.write(`<iframe width='100%' height='100%' src='${reader.result}'></iframe>`);
              };
            }
            
          }}>
          {item.input}
        </Button> :  
        <Button color="muted" disabled>
            No Choosen File
        </Button>
      } */}
        {file && !item.value
          ? file
          : !file && item.value
          ? item.value.name
          : file && item.value
          ? item.value.name
          : "No Choosen File"}
      </Row>
      <FormText color="muted">{item.description}</FormText>
      <FormInputError text={item.errMsg} />
    </FormGroup>
  );
};

export let CompoText = function ({
  fieldKey,
  item,
  disabled,
  onChangeField,
  onBlurField = () => {},
}) {
  if (item.format === "number") {
    return (
      <FormGroup>
        <Label> {item.title} </Label>
        <Input
          type={item.format ? item.format : "text"}
          placeholder={item.placeholder ? item.placeholder : ""}
          readOnly={item.readOnly}
          disabled={disabled}
          value={item.input ? item.input : ""}
          onKeyPress={(event) => {
            if (item.format === "number") {
              if (event.key === "-" || event.key === "e" || event.key === "+") {
                event.preventDefault();
              }
              if (item.preventDecimal) {
                if (event.key === "." || event.key === ",") {
                  event.preventDefault();
                }
              }
            }
          }}
          onChange={(event) => {
            onChangeField(fieldKey, event.target.value, item);
          }}
          onBlur={(event) => {
            onBlurField(fieldKey, event.target.value, item);
          }}
          min="1"
          //required
        />
        <FormInputError text={item.errMsg} />
      </FormGroup>
    );
  } else {
    return (
      <FormGroup>
        <Label> {item.title} </Label>
        <Input
          type={item.format ? item.format : "text"}
          placeholder={item.placeholder ? item.placeholder : ""}
          readOnly={item.readOnly}
          disabled={disabled}
          value={item.input ? item.input : ""}
          onChange={(event) => {
            onChangeField(fieldKey, event.target.value, item);
          }}
          onBlur={(event) => {
            onBlurField(fieldKey, event.target.value, item);
          }}
          //required
        />
        <FormInputError text={item.errMsg} />
      </FormGroup>
    );
  }
};

export let CompoTextarea = function ({
  fieldKey,
  item,
  disabled,
  onChangeField,
}) {
  return (
    <FormGroup>
      <Label> {item.title} </Label>
      <Input
        type="textarea"
        readOnly={item.readOnly}
        disabled={disabled}
        placeholder={item.placeholder}
        value={item.input ? item.input : ""}
        onChange={(event) => {
          onChangeField(fieldKey, event.target.value, item);
        }}
        //style={{ height: 100 }}
      />
      <FormText color="muted">{item.description}</FormText>
      <FormInputError text={item.errMsg} />
    </FormGroup>
  );
};

const DateCustomInput = ({ value, onClick, disabled }) => (
  <InputGroup>
    <InputGroupAddon addonType="append">
      <InputGroupText>
        <i className="fa fa-calendar"></i>
      </InputGroupText>
    </InputGroupAddon>
    <Input value={value} onClick={onClick} readonly disabled={disabled} />
  </InputGroup>
);

export let CompoDate = function ({ fieldKey, item, disabled, onChangeField }) {
  return (
    <FormGroup>
      <Label> {item.title} </Label>
      <DatePicker
        //name="preferredDate"
        //id="preferredDate"
        customInput={
          <DateCustomInput
            value={item.input ? item.input : ""}
            disabled={disabled}
          />
        }
        minDate={fieldKey == "dateOfAdmission" ? moment().toDate() : null}
        dateFormat="dd/MM/yyyy"
        className="form-control"
        selected={item.input ? item.input : ""}
        disabled={disabled}
        onChange={(event) => {
          onChangeField(fieldKey, event, item);
        }}
        minDate={item.minDate ? item.minDate : null}
        maxDate={item.maxDate ? item.maxDate : null}
        includeDates={item.includeDates ? item.includeDates : null}
        excludeDates={item.excludeDates ? item.excludeDates : null}
        //placeholderText="please select date"
        //excludeDates={this.props.bookingDateAvailability ? this.props.bookingDateAvailability.close.map(date => new Date(date)).concat(this.props.forms.secondPreferredDate) : null}
        //minDate={this.props.bookingDateAvailability ? new Date(this.props.bookingDateAvailability.start) : new Date()}
        //maxDate={this.props.bookingDateAvailability ? new Date(this.props.bookingDateAvailability.end) : new Date()}
        //onMonthChange={this.monthChageHandler}
      />
      <FormInputError text={item.errMsg} />
    </FormGroup>
  );
};

const TimeCustomInput = ({ value, onClick, disabled }) => (
  <InputGroup>
    <InputGroupAddon addonType="append">
      <InputGroupText>
        <i className="fa fa-clock"></i>
      </InputGroupText>
    </InputGroupAddon>
    <Input value={value} onClick={onClick} readonly disabled={disabled} />
  </InputGroup>
);

export let CompoTime = function ({ fieldKey, item, disabled, onChangeField }) {
  return (
    <FormGroup>
      <Label> {item.title} </Label>
      <DatePicker
        showTimeSelect
        showTimeSelectOnly
        timeCaption="Time"
        dateFormat="h:mm aa"
        className="form-control"
        disabled={disabled}
        customInput={
          <TimeCustomInput
            value={item.input ? item.input : ""}
            disabled={disabled}
          />
        }
        selected={item.input ? item.input : ""}
        onChange={(event) => {
          onChangeField(fieldKey, event, item);
        }}
      />
      <FormInputError text={item.errMsg} />
    </FormGroup>
  );
};

export let CompoCheckbox = function ({
  className,
  fieldKey,
  item,
  disabled,
  onChangeField,
}) {
  return (
    <FormGroup className="mb-3">
      <CustomInput
        id={item.id}
        htmlFor={item.id}
        type="checkbox"
        onChange={(event) => {
          console.log(event.target.checked);
          onChangeField(fieldKey, event.target.checked, item);
        }}
        checked={item.input}
        disabled={disabled}
        label={item.label}
      />
    </FormGroup>
  );
};

export let CompoRadioButton = function ({
  fieldKey,
  item,
  disabled,
  onChangeField,
}) {
  return (
    <FormGroup>
      <Label className="d-block"> {item.title} </Label>
      {item.choices
        ? item.choices.map(function (choice, index) {
            return (
              <Button
                color="info"
                className={`mx-2 px-4 ${
                  item.input == choice.value
                    ? "bg-primary text-white"
                    : "bg-white text-dark border"
                }`}
                onClick={() => {
                  onChangeField(fieldKey, choice.value, item);
                }}
                disabled={disabled}
              >
                {choice.label}
              </Button>
            );
          })
        : null}
      <FormInputError text={item.errMsg} />
    </FormGroup>
  );
};

export let CompoDropdown = function ({
  fieldKey,
  item,
  disabled,
  options,
  defaultOptions,
  isLoading,
  onChangeField,
  onInputChange,
}) {
  var arrOptions = options;
  var selected = item.input ? item.input : null;

  if (item.multi) {
    selected = [];
  }

  return (
    <FormGroup>
      {item.title && <Label> {item.title} </Label>}
      {
        // item.allowFreeText ? (
        //   <CreatableSelect
        //     isClearable
        //     options={arrOptions}
        //     //isSearchable={true}
        //     value={selected}
        //     isDisabled={disabled}
        //     isMulti={item.multi}
        //     closeMenuOnSelect={!item.multi}
        //     onChange={event => {
        //       var value = event ? event.value : '';
        //       onChangeField(fieldKey, value, item);
        //     }}
        //   />
        // ) :
        item.async ? (
          <AsyncSelect
            cacheOptions
            defaultOptions={defaultOptions}
            loadOptions={arrOptions}
            isOptionDisabled={(option) => option.disabled === true}
            isLoading={isLoading}
            value={selected}
            isClearable={false}
            isDisabled={disabled}
            isMulti={item.multi}
            closeMenuOnSelect={!item.multi}
            noOptionsMessage={({ inputValue }) =>
              inputValue ? "no options" : "Start typing to search"
            }
            // onInputChange={onInputChange}
            onChange={(event) => {
              var value = event && event.value ? event.value : "";
              if (Array.isArray(event)) {
                if (event.length == 0) {
                  value = "";
                } else {
                  for (var i = 0; i < event.length; i++) {
                    if (event[i].value) {
                      value += event[i].value + ",";
                    }
                  }
                  if (value.length > 0 && value[value.length - 1] == ",") {
                    value = value.substring(0, value.length - 1);
                  }
                }
              }

              onChangeField(fieldKey, event, item);
            }}
          />
        ) : (
          <ReactSelect
            options={arrOptions}
            isOptionDisabled={(option) => option.disabled === true}
            //isSearchable={true}
            value={selected}
            isClearable={false}
            isDisabled={disabled}
            isMulti={item.multi}
            closeMenuOnSelect={!item.multi}
            onChange={(event) => {
              var value = event && event.value ? event.value : "";
              if (Array.isArray(event)) {
                if (event.length == 0) {
                  value = "";
                } else {
                  for (var i = 0; i < event.length; i++) {
                    if (event[i].value) {
                      value += event[i].value + ",";
                    }
                  }
                  if (value.length > 0 && value[value.length - 1] == ",") {
                    value = value.substring(0, value.length - 1);
                  }
                }
              }

              onChangeField(fieldKey, event, item);
            }}
          />
        )
      }
      <FormInputError text={item.errMsg} />
    </FormGroup>
  );
};

export let CompoMultiDropdown = function ({
  fieldKey,
  item,
  disabled,
  options,
  defaultOptions,
  isLoading,
  onChangeField,
  onInputChange,
}) {
  var arrOptions = options;
  var selected = item.input ? item.input : null;

  return (
    <FormGroup>
      {item.title && <Label> {item.title} </Label>}
      <ReactSelect
        options={arrOptions}
        isOptionDisabled={(option) => option.disabled === true}
        value={selected}
        isClearable={false}
        isDisabled={disabled}
        isMulti={true}
        closeMenuOnSelect={false}
        onChange={(event) => {
          let value;
          if (Array.isArray(event)) {
            if (event.length == 0) {
              value = "";
            } else {
              for (var i = 0; i < event.length; i++) {
                if (event[i].value) {
                  value += event[i].value + ",";
                }
              }
              if (value.length > 0 && value[value.length - 1] == ",") {
                value = value.substring(0, value.length - 1);
              }
            }
          }

          console.log(value);

          onChangeField(fieldKey, event, item);
        }}
      />
      <FormInputError text={item.errMsg} />
    </FormGroup>
  );
};
