export const GET_ADD_VISIT_URL_REQUEST = "GET_ADD_VISIT_URL_REQUEST";
export const GET_ADD_VISIT_URL_SUCCESS = "GET_ADD_VISIT_URL_SUCCESS";
export const GET_ADD_VISIT_URL_FAILURE = "GET_ADD_VISIT_URL_FAILURE";

export const GET_VISIT_DETAIL_URL_REQUEST = "GET_VISIT_DETAIL_URL_REQUEST";
export const GET_VISIT_DETAIL_URL_SUCCESS = "GET_VISIT_DETAIL_URL_SUCCESS";
export const GET_VISIT_DETAIL_URL_FAILURE = "GET_VISIT_DETAIL_URL_FAILURE";

export const GET_ADD_CREDIT_NOTE_BILLING_URL_REQUEST = "GET_ADD_CREDIT_NOTE_BILLING_URL_REQUEST";
export const GET_ADD_CREDIT_NOTE_BILLING_URL_SUCCESS = "GET_ADD_CREDIT_NOTE_BILLING_URL_SUCCESS";
export const GET_ADD_CREDIT_NOTE_BILLING_URL_FAILURE = "GET_ADD_CREDIT_NOTE_BILLING_URL_FAILURE";

export const GET_VISIT_HISTORY_REQUEST = "GET_VISIT_HISTORY_REQUEST";
export const GET_VISIT_HISTORY_SUCCESS = "GET_VISIT_HISTORY_SUCCESS";
export const GET_VISIT_HISTORY_FAILURE = "GET_VISIT_HISTORY_FAILURE";
