import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import ReactLoading from "react-loading";
import * as Constant from "../../constant/general";
import Loadable from "react-loading-overlay";
import sha256 from "sha256";
import { FormInputError } from "../../components/FormInputError";

class ResetPassword extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      password: "",
      passwordConfirm: "",
      passwordErrMsg: "",
      passwordConfirmErrMsg: "",
      errorMsg: "",
      token: null,

      invalidToken: false,
      invalidMessage: "Invalid Token. Please try again.",
    };

    this.resetPasswordHandler = this.resetPasswordHandler.bind(this);
    this.backToLoginHandler = this.backToLoginHandler.bind(this);
  }

  async componentDidMount() {
    var invalidToken = false;
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get("token");
    if (!token) {
      invalidToken = true;
    } else {
      await this.props.verifyResetPasswordToken(token).then(() => {
        let { error } = this.props.payload;
        if (error) {
          invalidToken = true;
        }
      });
    }

    this.setState({ token, invalidToken });
  }

  verifyForm() {
    var error = false;
    var passwordErrMsg = "";
    var passwordConfirmErrMsg = "";

    if (!this.state.password) {
      passwordErrMsg =
        "Please enter password with at least 8 characters, contain lowercase letters, uppercase letters and numbers.";
      error = true;
    } else {
      passwordErrMsg = "";
    }
    if (this.state.password) {
      if (!this.state.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
        passwordErrMsg =
          "Your password is not strong. Passwords must have at least 8 characters, contain lowercase letters, uppercase letters and numbers.";
        error = true;
      } else {
        passwordErrMsg = "";
      }
    }

    if (this.state.passwordConfirm !== this.state.password) {
      passwordConfirmErrMsg =
        "Your passwords do not match. Please enter them again.";
      error = true;
    } else {
      passwordConfirmErrMsg = "";
    }

    this.setState({
      passwordErrMsg: passwordErrMsg,
      passwordConfirmErrMsg: passwordConfirmErrMsg,
    });

    return error;
  }

  resetPasswordHandler() {
    if (!this.verifyForm()) {
      let form = {
        token: this.state.token,
        password: sha256(this.state.password),
      };
      this.props.resetPasswordAction(form).then(() => {
        const { error } = this.props.payload;
        if (error) {
          this.setState({
            errorMsg: error.message || error,
          });
          return;
        }

        this.props.history.replace(Constant.ResetPasswordSuccess);
      });
    }
  }

  backToLoginHandler() {
    console.log("ResetPasswordSuccess: backToLoginHandler");

    this.props.history.replace(Constant.Login);
  }

  render() {
    return (
      <Loadable active={this.props.payload.loading} spinner text="Loading...">
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="6">
                {this.props.payload.loading ? null : (
                  <Card className="p-5">
                    <center>
                      <h1>Reset Password</h1>
                    </center>
                    <br />
                    {this.state.invalidToken ? (
                      <center className="text-danger">
                        <h2>{this.state.invalidMessage}</h2>
                      </center>
                    ) : (
                      <React.Fragment>
                        <Label htmlFor="password">New Password</Label>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            style={
                              this.state.passwordErrMsg
                                ? {
                                    borderColor: "red",
                                  }
                                : null
                            }
                            type="password"
                            value={this.state.password}
                            placeholder="Password"
                            onChange={(event) =>
                              this.setState({
                                password: event.target.value,
                              })
                            }
                          />
                        </InputGroup>
                        <FormInputError text={this.state.passwordErrMsg} />
                        <Label htmlFor="confirmPassword">
                          Confirm New Password
                        </Label>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            style={
                              this.state.passwordConfirmErrMsg
                                ? {
                                    borderColor: "red",
                                  }
                                : null
                            }
                            type="password"
                            value={this.state.passwordConfirm}
                            placeholder="Re-Enter Password"
                            onChange={(event) =>
                              this.setState({
                                passwordConfirm: event.target.value,
                              })
                            }
                          />
                        </InputGroup>
                        <FormInputError
                          text={this.state.passwordConfirmErrMsg}
                        />

                        <p>
                          * Password must have at least 8 characters, contain
                          lowercase letters, uppercase letters and numbers.
                        </p>

                        <br />

                        <FormInputError text={this.state.errorMsg} />

                        <Button
                          color="info"
                          className="px-4"
                          onClick={this.resetPasswordHandler}
                        >
                          RESET
                        </Button>
                      </React.Fragment>
                    )}
                  </Card>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </Loadable>
    );
  }
}

export default ResetPassword;
