import React, { Component } from "react";
import * as Constant from "../../constant/general";

class Logout extends Component {
  componentDidMount() {
    if (process.env.USE_SSO) {
      localStorage.removeItem("externalLogin");
      localStorage.removeItem("salt");
      localStorage.removeItem("sid");
      localStorage.removeItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
      this.props.history.replace("/");
    } else {
      localStorage.removeItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
      this.props.history.replace(Constant.Login);
    }
  }

  render() {
    return <div>Please wait...</div>;
  }
}

export default Logout;
