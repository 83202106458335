import * as types from "./SessionLogsActionTypes";

var initialState = {
  loading: false,
  result: [],
  error: null,
};

function SearchReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_SESSION_LOGS_REQUEST:
      return Object.assign({}, state, {
        result: [],
        loading: true,
        error: null,
      });
      break;

    case types.GET_SESSION_LOGS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        status: true,
        result: action.payload,
      });
      break;

    case types.GET_SESSION_LOGS_FAILURE:
      return Object.assign({}, state, {
        result: [],
        loading: false,
        error: action.error,
      });
      break;
    default:
      return state;
  }

  return state;
}

export default SearchReducer;
