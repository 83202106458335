import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import * as words from "./dictionary.json";

const langs = ["en", "id"];
const wordList = Object.keys(words);

// Map the languague.json into i18n resource object format
const resources = {};
for (let i in langs) {
  resources[langs[i]] = {};
  resources[langs[i]]["common"] = {};
  for (let j in wordList) {
    resources[langs[i]]["common"][wordList[j]] = words[wordList[j]][langs[i]];
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    debug: false,
    resources: resources,
    fallbackLng: "en",
    ns: ["common"],
    defaultNS: "common",
    react: {
      wait: false,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
    },
  });

export default i18n;
