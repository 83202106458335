import * as types from "./AccountActionTypes";

var initialState = {
  loading: false,
  accountDetail: null,
  result: null,
  error: null,
};

function AccountReducer(state = initialState, action) {
  switch (action.type) {
    case types.SELECT_ACCOUNT:
      return Object.assign({}, state, {
        accountDetail: action.account,
      });
    case types.GET_ACCOUNT_REQUEST:
    case types.ADD_ACCOUNT_REQUEST:
    case types.EDIT_ACCOUNT_REQUEST:
    case types.TOGGLE_ACCOUNT_STATUS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
      break;
    case types.GET_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        result: action.result,
        loading: false,
        error: null,
      });
      break;
    case types.ADD_ACCOUNT_SUCCESS:
    case types.EDIT_ACCOUNT_SUCCESS:
    case types.TOGGLE_ACCOUNT_STATUS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
      });
      break;
    case types.GET_ACCOUNT_FAILURE:
    case types.ADD_ACCOUNT_FAILURE:
    case types.EDIT_ACCOUNT_FAILURE:
    case types.TOGGLE_ACCOUNT_STATUS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      });
      break;
    default:
      return state;
  }

  return state;
}

export default AccountReducer;
