import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Search from "./Search";
import {
  getMemberV2,
  resetAction,
  getClinic,
  isEligibleForMedicalChit

} from "./SearchAction";

import {
  getAddVisitUrl,
  getVisitUrl,
  getAddCreditNoteBillingUrl,
  getVisitHistory

} from "./AddVisitAction";

const mapStateToProps = (state, ownProps) => {
  return {
    search: state.Search,
    addVisit: state.AddVisit
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMemberV2,
      resetAction,
      getClinic,
      isEligibleForMedicalChit,

      getAddVisitUrl,
      getVisitUrl,
      getAddCreditNoteBillingUrl,
      getVisitHistory

    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
