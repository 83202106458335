export const GET_ACCOUNT_REQUEST = "GET_ACCOUNT_REQUEST";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_FAILURE = "GET_ACCOUNT_FAILURE";
export const ADD_ACCOUNT_REQUEST = "ADD_ACCOUNT_REQUEST";
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_FAILURE = "ADD_ACCOUNT_FAILURE";
export const EDIT_ACCOUNT_REQUEST = "EDIT_ACCOUNT_REQUEST";
export const EDIT_ACCOUNT_SUCCESS = "EDIT_ACCOUNT_SUCCESS";
export const EDIT_ACCOUNT_FAILURE = "EDIT_ACCOUNT_FAILURE";
export const SELECT_ACCOUNT = "SELECT_ACCOUNT";
export const TOGGLE_ACCOUNT_STATUS_REQUEST = "TOGGLE_ACCOUNT_STATUS_REQUEST";
export const TOGGLE_ACCOUNT_STATUS_SUCCESS = "TOGGLE_ACCOUNT_STATUS_SUCCESS";
export const TOGGLE_ACCOUNT_STATUS_FAILURE = "TOGGLE_ACCOUNT_STATUS_FAILURE";
