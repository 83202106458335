import axios from "axios";
// import CustomFormData from '../../utility/CustomFormData';
import AlertError from "../../utility/AlertError";
import * as types from "./SessionLogsActionTypes";
import * as Constant from "../../constant/general";
const BASE_URL = process.env.BASE_URL;

export function getSessionLogs() {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_SESSION_LOGS_REQUEST });

    var user = getState().Auth.result;
    console.log("user", user);

    await axios({
      method: "get",
      url: "/v1/session/logs",
      headers: {
        authorization: user.access_token,
      },
    }).then((response) => {
      if (response.data && response.data.result) {
        dispatch({
          type: types.GET_SESSION_LOGS_SUCCESS,
          payload: response.data.result,
        });
      } else {
        var err_msg =
          response.response.data.error || response.response.data.message;
        let alertError = new AlertError(
          response.response.status,
          "Error",
          err_msg
        );
        dispatch({
          type: types.GET_SESSION_LOGS_FAILURE,
          error: alertError,
        });
        if (response.response.status == 401) {
          localStorage.removeItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
        }
      }
    });
  };
}
