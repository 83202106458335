import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Input,
  InputGroup,
  // InputGroupAddon,
  // InputGroupText
} from "reactstrap";
import * as Constant from "../../constant/general";

class Verification extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      otp: "",
      email: "",
      passcodeErrMsg: "",
    };
    this.proceedHandler = this.proceedHandler.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }
  componentDidMount() {
    this.setState({
      email: JSON.parse(
        localStorage.getItem(Constant.LOCAL_STORAGE_LOGIN_SESSION)
      ).email,
    });
  }
  proceedHandler() {
    if (this.verifyPasscode()) {
      this.props.verifyAction(this.state.email, this.state.otp).then(() => {
        if (this.props.payload.result.success) {
          this.props.history.push(Constant.Search);
        } else {
          this.setState({
            passcodeErrMsg: this.props.payload.result.message,
          });
        }
      });
    }
  }
  verifyPasscode() {
    this.setState({
      passcodeErrMsg: "",
    });
    if (!this.state.otp) {
      this.setState({
        passcodeErrMsg: "Passcode cannot be empty",
      });
      return false;
    }
    if (this.state.otp.length > 6 || this.state.otp.length < 6) {
      this.setState({
        passcodeErrMsg: "The passcode length is 6",
      });
      return false;
    }
    return true;
  }
  keyPress(e) {
    if (e.key === "Enter") {
      this.loginHandler();
    }
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="5">
              <Card className="p-4">
                <h1>Verification Page</h1>
                <p>
                  An email has been sent to you. Plese enter the passcode
                  provided to proceed.
                </p>
                <InputGroup className="mb-3">
                  <Input
                    type="text"
                    placeholder="Enter Passcode"
                    value={this.state.otp}
                    onChange={(e) =>
                      this.setState({
                        otp: e.target.value,
                      })
                    }
                    onKeyPress={this.keyPress}
                    style={{
                      borderRadius: "0",
                    }}
                  ></Input>
                  <Button
                    style={{
                      borderRadius: "0",
                      marginLeft: 10,
                    }}
                    color="info"
                  >
                    Resend(15)
                  </Button>
                </InputGroup>
                {this.state.passcodeErrMsg ? (
                  <p style={{ color: "red", fontSize: "10px" }}>
                    {this.state.passcodeErrMsg}
                  </p>
                ) : null}
                <br />
                <Row className="justify-content-center">
                  <Button
                    color="info"
                    className="px-4"
                    onClick={this.proceedHandler}
                  >
                    Proceed
                  </Button>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Verification;
