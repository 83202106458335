import * as types from "./RoleActionTypes";

var initialState = {
  loading: false,
  result: null,
  error: null,
};

function RoleReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ROLE_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
      break;
    case types.GET_ROLE_SUCCESS:
      return Object.assign({}, state, {
        result: action.result,
        loading: false,
        error: null,
      });
      break;
    case types.GET_ROLE_FAILURE:
      return Object.assign({}, state, {
        modal: false,
        loading: false,
        error: action.error,
      });
      break;
    default:
      return state;
  }

  return state;
}

export default RoleReducer;
