import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import ReactLoading from "react-loading";
import Loadable from "react-loading-overlay";
import * as Constant from "../../constant/general";

class ForgotPasswordSuccess extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <Loadable active={this.props.payload.loading} spinner text="Loading...">
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="6">
                {this.props.payload.loading ? null : (
                  <Card className="p-5">
                    <React.Fragment>
                      <center>
                        <h1>Check Your Email</h1>
                      </center>
                      <br />
                      <p>
                        We've sent you an email with a link to finish resetting
                        your password.
                      </p>
                      <p>
                        Can't find the email? Try checking your spam folder.
                      </p>
                      <br />
                      <Button
                        color="info"
                        className="px-4"
                        onClick={() =>
                          this.props.history.replace(Constant.Login)
                        }
                      >
                        Back to Login
                      </Button>
                    </React.Fragment>
                  </Card>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </Loadable>
    );
  }
}

export default ForgotPasswordSuccess;
