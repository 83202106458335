import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Badge,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
} from "reactstrap";
import PropTypes from "prop-types";
import * as Constant from "../../constant/general";

import {
  AppAsideToggler,
  AppNavbarBrand,
  AppSidebarToggler,
} from "@coreui/react";
import logo from "../../assets/img/brand/aviva.jpg";
// import sygnet from '../../assets/img/brand/sygnet.svg';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: null
    };
  }
  componentDidMount() {
    let profile = localStorage.getItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
    this.setState({
      profile: JSON.parse(profile)
    });
  }

  render() {
    // console.log(this.state, "profile");
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    // const name = JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_LOGIN_SESSION)).name;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-md-none" display="md" mobile />
        {/* <AppNavbarBrand
          full={{ src: logo, width: 60, height: 25, alt: 'Aviva' }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: 'Aviva' }}
        /> */}
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink to="#" className="nav-link text-dark font-weight-bold px-2">
              <h5 className="m-0">{this.state.profile ? this.state.profile.name : null}</h5>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to={Constant.Logout} className="nav-link px-2 text-danger border-left">Logout</NavLink>
          </NavItem>
        </Nav>
      </React.Fragment>
    );
  }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
