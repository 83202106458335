import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppAccessLog from "./AppAccessLogs";
import { getAll, getLogs, exportData } from "./AppAccessLogsAction";

const mapStateToProps = (state, ownProps) => {
  return {
    // search: state.Search,
    appAccessLogs: state.AppAccessLogs,

  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getAll, getLogs, exportData
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AppAccessLog);
