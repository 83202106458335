import React, { Component } from "react";
import queryString from "query-string";
import * as Constant from "./constant/general";
import {
  accountLoggedIn,
  authenticateSSOUser,
  // store_authenticateSSOUser,
} from "./modules/Auth/AuthAction";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Loadable from "react-loading-overlay";

class PrivateRoute extends Component {
  UNSAFE_componentWillMount() {
    if (window.location.pathname.includes("hash")) {
      console.log("UNSAFE_componentWillMount ");

      // let url = encodeURIComponent(window.location);
      let url = window.location.href.replace("#/", "").replace("hash", "#");
      url = url.split("?");
      url = encodeURIComponent(url[0]);
      console.log("url: ", url);
      // return
      let { sid, salt } = queryString.parse(window.location.search);
      if (sid && salt) {
        sid = queryString.parse(window.location.search).sid;
        salt = queryString.parse(window.location.search).salt;
        localStorage.setItem("s_sid", sid);
        localStorage.setItem("s_salt", salt);
        console.log("localStorage setItem s_sid, s_salt: ", sid, salt);
      } else {
        if (localStorage.getItem("s_sid") && localStorage.getItem("s_salt")) {
          sid = localStorage.getItem("s_sid");
          salt = localStorage.getItem("s_salt");
          console.log("localStorage getItem s_sid, s_salt: ", sid, salt);
        } else {
          salt = process.env.SSO_APP_SALT;
          console.log("Redirectto SSO");

          window.location.assign(
            `${process.env.SSO_URL}/login?redirect=${url}&salt=${salt}`
          );
          return;
        }
      }
      // this.props.store_authenticateSSOUser(salt, sid, url).then(() => {
      //   // if(window.location.search){
      //   let redirect_to = window.location.href.replace("#/", "");
      //   redirect_to = redirect_to.replace("hash", "#");
      //   redirect_to = redirect_to.split("?");
      //   console.log("Redirectto store", redirect_to[0]);

      //   window.location.replace(redirect_to[0]);
      //   // }
      // });

      // http://localhost:8080/hash/store/slug_1?salt=pXFFhCQ.KFG3L35.q5Pp6NBlfXVv.1&sid=e8c4cfcd3b55f097df9c5acf8e460b96
    } else {
      if (process.env.USE_SSO) {
        let externalLogin = localStorage.getItem("externalLogin");

        if (externalLogin && externalLogin == "true") {
          return;
        }

        let url = encodeURIComponent(window.location);
        let { sid, salt } = queryString.parse(window.location.search);
        if (sid && salt) {
          sid = queryString.parse(window.location.search).sid;
          salt = queryString.parse(window.location.search).salt;
          localStorage.setItem("sid", sid);
          localStorage.setItem("salt", salt);
        } else {
          if (localStorage.getItem("sid") && localStorage.getItem("salt")) {
            sid = localStorage.getItem("sid");
            salt = localStorage.getItem("salt");
          } else {
            salt = process.env.SSO_APP_SALT;
            window.location.assign(
              `${process.env.SSO_URL}/login?redirect=${url}&salt=${salt}`
            );
            return;
          }
        }
        this.props.authenticateSSOUser(salt, sid, url);
        if (window.location.search) {
          window.location.replace("/");
        }
      }
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    if (process.env.USE_SSO) {
      return (
        <Route
          {...rest}
          render={(props) =>
            accountLoggedIn() ? (
              <Component {...props} />
            ) : (
              <Loadable
                active={this.props.Auth.loading}
                spinner
                text="Loading ..."
              ></Loadable>
            )
          }
        />
      );
    } else {
      return (
        <Route
          {...rest}
          render={(props) =>
            accountLoggedIn() ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: Constant.Login,
                  state: { from: props.location },
                }}
              />
            )
          }
        />
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    Auth: state.Auth,
  };
};

export default connect(mapStateToProps, {
  authenticateSSOUser,
  // store_authenticateSSOUser,
})(PrivateRoute);
