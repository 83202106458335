import axios from "axios";
// import CustomFormData from '../../utility/CustomFormData';
import AlertError from "../../utility/AlertError";
import * as types from "./SearchActionTypes";
import * as Constant from "../../constant/general";
const BASE_URL = process.env.BASE_URL;

export function getMemberV2({ nric, visitNo, patientName, company, date, type, search }) {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_MEMBER_REQUEST });

    var user = getState().Auth.result;
    let url = "/v1/search/v2";
    let bodyData = { nric, visitNo, patientName, date, type }
    if (company) {
      url = "/v1/search/company";
      bodyData = { nric, visitNo, patientName, date, type, company }
    }
    await axios({
      method: "POST",
      url: url,
      data: bodyData,
      headers: {
        authorization: user.access_token,
      },
    }).then((response) => {
      if (response.data && response.data.result) {
        dispatch({
          type: types.GET_MEMBER_SUCCESS,
          payload: response.data.result,
        });
      } else {
        var err_msg =
          response.response.data.error || response.response.data.message;
        let alertError = new AlertError(
          response.response.status,
          "Error",
          err_msg
        );
        dispatch({
          type: types.GET_MEMBER_FAILURE,
          error: alertError,
        });
        if (response.response.status == 401) {
          localStorage.removeItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
        }
      }
    });
  };
}

export function getMemberAIA(nric, date) {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_MEMBER_AIA_REQUEST });

    var user = getState().Auth.result;

    await axios({
      method: "POST",
      url: "/v1/search/aia",
      data: {
        nric: nric,
        date: date,
      },
      headers: {
        authorization: user.access_token,
      },
    }).then((response) => {
      if (response.data && response.data.result) {
        dispatch({
          type: types.GET_MEMBER_AIA_SUCCESS,
          payload: response.data.result,
        });
      } else {
        var err_msg =
          response.response.data.error || response.response.data.message;
        let alertError = new AlertError(
          response.response.status,
          "Error",
          err_msg
        );
        dispatch({
          type: types.GET_MEMBER_AIA_FAILURE,
          error: alertError,
        });
        if (response.response.status == 401) {
          localStorage.removeItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
        }
      }
    });
  };
}

export function getMemberAviva(nric, date) {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_MEMBER_AVIVA_REQUEST });

    var user = getState().Auth.result;

    await axios({
      method: "POST",
      url: "/v1/search/aviva",
      data: {
        nric: nric,
        date: date,
      },
      headers: {
        authorization: user.access_token,
      },
    }).then((response) => {
      if (response.data && response.data.result) {
        dispatch({
          type: types.GET_MEMBER_AVIVA_SUCCESS,
          payload: response.data.result,
        });
      } else {
        var err_msg =
          response.response.data.error || response.response.data.message;
        let alertError = new AlertError(
          response.response.status,
          "Error",
          err_msg
        );
        dispatch({
          type: types.GET_MEMBER_AVIVA_FAILURE,
          error: alertError,
        });
        if (response.response.status == 401) {
          localStorage.removeItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
        }
      }
    });
  };
}

export function resetAction() {
  return function (dispatch, state) {
    dispatch({ type: types.RESET_TABLE });
  };
}

export function getClinic() {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_CLINIC_REQUEST });

    var user = getState().Auth.result;

    await axios({
      method: "POST",
      url: "/v1/search/clinics",
      headers: {
        authorization: user.access_token,
      },
    }).then((response) => {
      if (response.data && response.data.result) {
        dispatch({
          type: types.GET_CLINIC_SUCCESS,
          payload: response.data.result,
        });
      } else {
        var err_msg =
          response.response.data.error || response.response.data.message;
        let alertError = new AlertError(
          response.response.status,
          "Error",
          err_msg
        );
        dispatch({
          type: types.GET_CLINIC_FAILURE,
          error: alertError,
        });
        if (response.response.status == 401) {
          localStorage.removeItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
        }
      }
    });
  };
}

export function isEligibleForMedicalChit() {
  return async (dispatch, getState) => {
    dispatch({ type: types.IS_ELIGIBLE_FOR_MEDICAL_CHIT_REQUEST });

    var user = getState().Auth.result;

    await axios({
      method: "GET",
      url: "/v1/other/medicalchit/eligible",
      headers: {
        authorization: user.access_token,
      },
    }).then((response) => {
      if (response.data && response.data.result) {
        dispatch({
          type: types.IS_ELIGIBLE_FOR_MEDICAL_CHIT_SUCCESS,
          payload: response.data.result,
        });
      } else {
        var err_msg =
          response.response.data.error || response.response.data.message;
        let alertError = new AlertError(
          response.response.status,
          "Error",
          err_msg
        );
        dispatch({
          type: types.IS_ELIGIBLE_FOR_MEDICAL_CHIT_FAILURE,
          error: alertError,
        });
        if (response.response.status == 401) {
          localStorage.removeItem(Constant.LOCAL_STORAGE_LOGIN_SESSION);
        }
      }
    });
  };
}

