import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import AlertModalReducer from "./components/AlertModal/AlertModalReducer";
import AuthReducer from "./modules/Auth/AuthReducer";
import AccountReducer from "./modules/Account/AccountReducer";

import AddVisitReducer from "./modules/Search/AddVisitReducer";

import RoleReducer from "./modules/Account/RoleReducer";

import SearchReducer from "./modules/Search/SearchReducer";
import SessionLogsReducer from "./modules/SessionLogs/SessionLogsReducer";
import AppAccessLogsReducer from "./modules/AppAccessLogs/AppAccessLogsReducer";
import SearchLogsReducer from "./modules/SearchLogs/SearchLogsReducer";



const appReducer = combineReducers({
  AlertModal: AlertModalReducer,
  Auth: AuthReducer,
  Account: AccountReducer,
  Role: RoleReducer,
  Search: SearchReducer,
  SessionLogs: SessionLogsReducer,
  AddVisit: AddVisitReducer,
  AppAccessLogs : AppAccessLogsReducer,
  SearchLogs: SearchLogsReducer,
});

const RootReducer = (state, action) => {
  return appReducer(state, action);
};

export default RootReducer;
