import React, { Component } from "react";
import {
  Alert,
  Badge,
  Card,
  CardBody,
  Col,
  Row,
  CardHeader

} from "reactstrap";
import swal from "sweetalert";
import moment from "moment";
import Loadable from "react-loading-overlay";
import ReactJson from "react-json-view";


class MemberDetailAviva extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchNRIC: "",
      searchName: "",
      date: moment().format("YYYY-MM-DD"),
      item: null,
      jsonResult: null,
    };

  }

  componentDidMount() {
    const { error: errorAviva, result } = this.props.search;
    if (errorAviva) {
      console.log("error.code", errorAviva.code);

      swal({
        title: `${errorAviva.title} ${errorAviva.code}`,
        text: JSON.stringify(errorAviva.message),
        icon: "error",
        // timer: 1000,
      });
      return;
    }
    const resultAviva = result.memberDetail
    // console.log("resultAviva", resultAviva);

    if (
      resultAviva &&
      resultAviva.members &&
      resultAviva.members.length > 0
    ) {
      this.setState({
        jsonResult: resultAviva,
        item: resultAviva.members[0],
      });
    } else {
      swal({
        title: "Member not found",
        text: "",
        icon: "error",
        // timer: 1000,
      });
      this.setState({
        item: null,
        jsonResult: null,
      });
    }
  }




  render() {
    // console.log(this.props, "PROPS MEMBER DETAIL AVIVA")


    let item = {};
    if (this.state.item) {
      item = this.state.item;
    }

    return (
      <React.Fragment>
        <div className="animated fadeIn">
          <Loadable
            active={this.props.search.loading}
            spinner
            text="Loading..."
          >
            <Row>
              <Card style={{ width: "100%" }}>
                <CardHeader>
                  Singlife Member Detail
                </CardHeader>
                <CardBody>
                  <table
                    width="100%"
                    border="0"
                    cellspacing="2"
                    cellpadding="2"
                  >
                    <col width="200px" />
                    <col width="auto" />
                    <tr>
                      <td className="table-left">Policy No</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.policyNo }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Plan Outsrc Code </td>
                      <td className="table-right">
                        {item.productList
                          ? item.productList[0].planOutsrcAgent == "MYB"
                            ? item.productList[0].planOutsrcAgent +
                            " (This member is under GLOBAL 8)"
                            : item.productList[0].planOutsrcAgent +
                            " (This member is under AVIVA)"
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-left">Employee/Dependant </td>
                      <td className="table-right">
                        {item.memberRelCd
                          ? item.memberRelCd == "SELF"
                            ? "Member"
                            : "Dependant"
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-left">NRIC</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.memberICNo }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left"> Name</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html: item.memberNm,
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left"> DOB</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html: item.memberDOB
                            ? moment(item.memberDOB, "DD/MM/YYYY").format(
                              "YYYY-MM-DD"
                            )
                            : "",
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left"> Gender</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.memberGenCd }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Employee NRIC</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.employeeNRIC }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Employee Name</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html: item.employeeNm,
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Employee Email</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.employeeEmail }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Employee Mobile No.</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html: item.employeeMobileNo
                            ? item.employeeMobileCtryCd +
                            " " +
                            item.employeeMobileNo
                            : "",
                        }}
                      ></td>
                    </tr>

                    <tr>
                      <td className="table-left">Sub Off Name </td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{ __html: item.policyGroupNm }}
                      ></td>
                    </tr>

                    <tr>
                      <td className="table-left">Aviva IEC Policy Start </td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html: item.policyCommDt
                            ? moment(item.policyCommDt, "DD/MM/YYYY").format(
                              "YYYY-MM-DD"
                            )
                            : "",
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Aviva IEC Policy End </td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html: item.policyExpiryDt
                            ? moment(item.policyExpiryDt, "DD/MM/YYYY").format(
                              "YYYY-MM-DD"
                            )
                            : "",
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Product Type Code </td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html: item.productList
                            ? item.productList[0].productCd
                            : "",
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Plan Effective From Date </td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html:
                            item.productList && item.productList.length > 0
                              ? moment(
                                item.productList[0].planEffFromDt,
                                "DD/MM/YYYY"
                              ).format("YYYY-MM-DD")
                              : "",
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Plan Effective To Date </td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html:
                            item.productList && item.productList.length > 0
                              ? moment(
                                item.productList[0].planEffToDt,
                                "DD/MM/YYYY"
                              ).format("YYYY-MM-DD")
                              : "",
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">pcpCoGP </td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html: item.productList
                            ? item.productList[0].pcpCoGP
                            : "",
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">pcpCoSP</td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html: item.productList
                            ? item.productList[0].pcpCoSP
                            : "",
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Card Type </td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html: item.productList
                            ? item.productList[0].cardType
                            : "",
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td className="table-left">Out src Ref Cd </td>
                      <td
                        className="table-right"
                        dangerouslySetInnerHTML={{
                          __html: item.productList
                            ? item.productList[0].outsrcRefCd
                            : "",
                        }}
                      ></td>
                    </tr>
                  </table>
                  <br />
                  {this.state.jsonResult && (
                    <ReactJson
                      name={false}
                      enableClipboard={false}
                      displayObjectSize={false}
                      displayDataTypes={false}
                      src={this.state.jsonResult}
                    />
                  )}
                </CardBody>
              </Card>
            </Row>
          </Loadable>
        </div>
      </React.Fragment>
    );
  }
}

export default MemberDetailAviva;
