import React, { Component } from "react";
import {
  Alert,
  Badge,
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Button,
} from "reactstrap";
import { FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import { MdModeEdit, MdAdd } from "react-icons/md";
import { AiOutlineStop } from "react-icons/ai";
import ReactTable from "react-table";
import "react-table/react-table.css";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
import * as Constant from "../../constant/general";
import swal from "sweetalert";
import SwalLoading from "../../components/SwalLoading";
import moment from "moment";

const ReactTableFixedCol = withFixedColumns(ReactTable);

class AppAccess extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="animated fadeIn">AppAccess</div>
      </React.Fragment>
    );
  }
}

export default AppAccess;
