import React from 'react';
import {
    Badge,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardHeader,
    CardBody,
    Col,
    Row,
    UncontrolledTooltip,
    Table
} from 'reactstrap';
import { CompoDropdown } from "../../components/FormComponents";


import 'react-table/react-table.css';

class AddVisit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1,
            modal: false,
            clinicSelected: null
        };

        this.toggle = this.toggle.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.onChangeField = this.onChangeField.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    toggle() {
        // add credit note handle
        if (this.props.isClinic === false && this.props.data.cc && this.props.type === "creditNote" && this.props.data) {
            const patientId = this.props.data.did ? this.props.data.did : this.props.data.eid ? this.props.data.eid : this.props.data.nric ? this.props.data.nric : null;
            const userId = this.props.data.cc ? "3GSW" + this.props.data.cc : null;
            const { coc, cid, cc, gc, ws, vd, sd, td } = this.props.data
            const vc = this.props.visitNumber
            this.props.onSubmitAddVisit(userId, this.props.type, patientId, this.props.visitDate, this.props.data.ws, this.props.data.isIEC, sd, td, cid, { cc, cid, coc, gc, vc, patientId, vd, portal: ws })
            // this.props.onSubmitAddCreditNote(cc, cid, coc, gc, vc)

        } else if (this.props.isClinic === true || this.props.data.cc) {
            const patientId = this.props.data.did ? this.props.data.did : this.props.data.eid ? this.props.data.eid : this.props.data.nric ? this.props.data.nric : null;
            const userId = this.props.data.cc ? "3GSW" + this.props.data.cc : null;
            this.props.onSubmitAddVisit(userId, this.props.type, patientId, this.props.visitDate, this.props.data.ws, this.props.data.isIEC, this.props.data.sd, this.props.data.td, this.props.data.cid)
        } else {
            this.setState(prevState => ({
                modal: !prevState.modal
            }));

        }
    }
    onChangeField(fieldKey, event, item) {
        this.setState(prevState => ({
            clinicSelected: event
        }));
    }
    toggleTab(value) {
        this.setState(prevState => {
            if (prevState.tab !== value) {
                return {
                    tab: value
                }
            }
        })
    }
    onSubmit() {
        this.props.onSubmitAddVisit(this.state.clinicSelected.value, this.props.type, this.props.data.did ? this.props.data.did : this.props.data.eid ? this.props.data.eid : this.props.data.nric ? this.props.data.nric : null, this.props.visitDate, this.props.data.ws, this.props.data.isIEC, this.props.data.sd, this.props.data.td, this.props.data.cid).then(() => {
            // console.log("SUBMITTED");
        })
    }

    render() {
        // console.log(this.props, "PROPS ADD VISIT");
        return (
            <div>
                <Button
                    outline={this.props.buttonOutline}
                    block={this.props.buttonBlock}
                    color={this.props.buttonColor || 'info'}
                    className={this.props.buttonClass}
                    onClick={this.toggle}
                    style={{ width: 170 }}
                >
                    {this.props.buttonLabel}
                </Button>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    size='md'
                // centered
                >
                    <ModalHeader>{this.props.buttonLabel}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <CompoDropdown
                                    fieldKey="requestStatus"
                                    // onChangeField={this.onChangeFieldAction}
                                    onChangeField={this.onChangeField}
                                    item={{
                                        title: "Select Clinic",
                                        readyOnly: false,
                                        input: this.state.clinicSelected,

                                    }}
                                    // style={{ color: "red", width: "100%" }}
                                    options={this.props.dropdownOptions && this.props.dropdownOptions.length > 0 && this.props.dropdownOptions.map((el) => ({ value: el.userId, label: `${el.clinicCode} | ${el.clinicName}` }))
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    color='primary'
                                    onClick={this.onSubmit}
                                    disabled={this.props.dropdownOptions && this.props.dropdownOptions.length > 0 ? false : true}
                                >Go to William System
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </div >
        );
    }
}

export default AddVisit;
