import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import ReactLoading from "react-loading";
import * as Constant from "../../constant/general";
import Loadable from "react-loading-overlay";
import { FormInputError } from "../../components/FormInputError";

class ForgotPassword extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: "",
      emailErrMsg: "",
      errorMsg: "",
    };

    this.forgotPasswordRequestHandler =
      this.forgotPasswordRequestHandler.bind(this);
    this.backToLoginHandler = this.backToLoginHandler.bind(this);
    this.verifyForm = this.verifyForm.bind(this);
  }

  verifyForm() {
    this.setState({
      emailErrMsg: "",
      errorMsg: "",
    });

    if (!this.state.email) {
      this.setState({
        emailErrMsg: "Email cannot be empty",
      });

      return false;
    }

    return true;
  }

  forgotPasswordRequestHandler() {
    if (this.verifyForm()) {
      var params = {
        email: this.state.email,
      };

      this.props.requestResetPassword(params).then(() => {
        const { error } = this.props.payload;
        if (error) {
          this.setState({
            errorMsg: error.message,
          });
          return;
        }

        this.props.history.replace(Constant.ForgotPasswordSuccess);
      });
    }
  }

  backToLoginHandler() {
    this.props.history.goBack();
  }

  keyPress(event) {
    if (event.key === "Enter") {
      this.forgotPasswordRequestHandler();
    }
  }

  render() {
    return (
      <Loadable active={this.props.payload.loading} spinner text="Loading...">
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="6">
                <Card className="p-4">
                  <CardBody className="text-center">
                    <Form>
                      <h1>Forgot Password</h1>
                      <p className="text-muted">Input your email address</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-envelope"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="email"
                          placeholder="Email"
                          value={this.state.email}
                          onChange={(event) =>
                            this.setState({
                              email: event.target.value,
                            })
                          }
                          onKeyPress={this.keyPress}
                        />
                      </InputGroup>
                      <FormInputError text={this.state.emailErrMsg} />
                      <FormInputError
                        text={this.state.errorMsg}
                        fontSize="15px"
                      />
                      <Row>
                        <Col className="text-center">
                          <Button
                            color="info"
                            className="px-4"
                            onClick={this.forgotPasswordRequestHandler}
                            onKeyPress={this.keyPress}
                            block
                          >
                            Proceed
                          </Button>
                          <br />
                          <Button
                            color="link"
                            onClick={this.backToLoginHandler}
                          >
                            Back
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br />
            <Row className="justify-content-center">
              <h6 style={{ fontSize: 12 }}>
                Having trouble logging in?
                <font color="#21A8D8"> Contact us at 6305 4578</font>
              </h6>
            </Row>
          </Container>
        </div>
      </Loadable>
    );
  }
}

export default ForgotPassword;
