import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Constant from "./../../constant/general";
import moment from "moment";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class Footer extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <span>
          <a href="https://coreui.io">{process.env.COMPANY}</a> &copy;{" "}
          {moment(new Date()).format("YYYY")}
        </span>
        <span className="ml-auto">
          Powered by <a href="https://coreui.io/react">{process.env.VENDOR}</a>
        </span>
      </React.Fragment>
    );
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
