import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ForgotPasswordSuccess from "./ForgotPasswordSuccess";
import { requestResetPassword } from "./AuthAction";

const mapStateToProps = (state, ownProps) => {
  return {
    payload: state.Auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      requestResetPassword,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordSuccess);
