import * as Constant from "./constant/general";

function generateNav(key) {
  let nav = [
    {
      name: "Search Member / Visits",
      url: Constant.Search,
      icon: "fa fa-search",
    },
  ];

  if (key && key.toLowerCase() === "admin") {
    nav = nav.concat([
      // {
      //   name: "Search Member (AIA)",
      //   url: Constant.SearchAIA,
      //   icon: "fa fa-search",
      // },
      // {
      //   name: "Search Member (Singlife)",
      //   url: Constant.SearchAviva,
      //   icon: "fa fa-search",
      // },
      {
        name: "Session Logs",
        url: Constant.SessionLogs,
        icon: "fa fa-file-code",
      },
      {
        name: "Search Logs",
        url: Constant.SearchLogs,
        icon: "fa fa-history",
      },
      {
        name: "App Access Logs",
        url: Constant.AppAccessLogs,
        icon: "fa fa-history",
      },
    ]);
  }

  if (key && key.toLowerCase() === "clinic") {
  }

  nav.push({
    name: "Logout",
    url: Constant.Logout,
    icon: "fa fa-sign-out-alt",
  });

  return nav;
}

export default generateNav;
