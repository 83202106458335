import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ResetPassword from "./ResetPassword";
import { resetPasswordAction, verifyResetPasswordToken } from "./AuthAction";

const mapStateToProps = (state, ownProps) => {
  return {
    payload: state.Auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetPasswordAction,
      verifyResetPasswordToken,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
