export const GET_MEMBER_REQUEST = "GET_MEMBER_REQUEST";
export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
export const GET_MEMBER_FAILURE = "GET_MEMBER_FAILURE";

export const GET_MEMBER_AIA_REQUEST = "GET_MEMBER_AIA_REQUEST";
export const GET_MEMBER_AIA_SUCCESS = "GET_MEMBER_AIA_SUCCESS";
export const GET_MEMBER_AIA_FAILURE = "GET_MEMBER_AIA_FAILURE";

export const GET_MEMBER_AVIVA_REQUEST = "GET_MEMBER_AVIVA_REQUEST";
export const GET_MEMBER_AVIVA_SUCCESS = "GET_MEMBER_AVIVA_SUCCESS";
export const GET_MEMBER_AVIVA_FAILURE = "GET_MEMBER_AVIVA_FAILURE";

export const GET_CLINIC_REQUEST = "GET_CLINIC_REQUEST";
export const GET_CLINIC_SUCCESS = "GET_CLINIC_SUCCESS";
export const GET_CLINIC_FAILURE = "GET_CLINIC_FAILURE";

export const IS_ELIGIBLE_FOR_MEDICAL_CHIT_REQUEST = "IS_ELIGIBLE_FOR_MEDICAL_CHIT_REQUEST";
export const IS_ELIGIBLE_FOR_MEDICAL_CHIT_SUCCESS = "IS_ELIGIBLE_FOR_MEDICAL_CHIT_SUCCESS";
export const IS_ELIGIBLE_FOR_MEDICAL_CHIT_FAILURE = "IS_ELIGIBLE_FOR_MEDICAL_CHIT_FAILURE";

export const RESET_TABLE = "RESET_TABLE";
