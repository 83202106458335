import * as types from "./AuthActionTypes";

var initialState = {
  loading: false,
  result: null,
  error: null,
};

function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case types.AUTHENTICATE_INTERNAL_USER_REQUEST:
    case types.AUTHENTICATE_SSO_USER_REQUEST:
    case types.ACTION_REQUEST:
    case types.VERIFY_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
      break;
    case types.LOGIN_SUCCESS:
    case types.AUTHENTICATE_INTERNAL_USER_SUCCESS:
    case types.AUTHENTICATE_SSO_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        status: true,
        result: action.payload,
      });
      break;
    case types.LOGIN_FAILURE:
    case types.AUTHENTICATE_INTERNAL_USER_FAILURE:
    case types.AUTHENTICATE_SSO_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        status: false,
        error: action.error,
      });
      break;
    case types.LOGOUT:
      return Object.assign({}, state, initialState);
      break;
    case types.VERIFY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        status: true,
        result: action.payload,
      });
      break;
    case types.VERIFY_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        status: false,
        result: action.payload,
      });
      break;
    case types.RESET_PASSWORD_REQUEST_SUCCESS:
    case types.RESET_PASSWORD_SUCCESS:
    case types.REGISTER_SUCCESS:
    case types.RESET_PASSWORD_SUCCESS:
    case types.FORGOT_SUCCESS:
      return Object.assign({}, state, {
        result: action.result,
        loading: false,
        status: false,
        error: null,
      });
      break;
    case types.RESET_PASSWORD_REQUEST_FAILURE:
    case types.RESET_PASSWORD_FAILURE:
    case types.REGISTER_FAILURE:
    case types.RESET_PASSWORD_FAILURE:
    case types.FORGOT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        status: false,
        error: action.error,
      });
      break;
    default:
      return state;
  }

  return state;
}

export default AuthReducer;
